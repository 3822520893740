import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

const tabStyle = {
  textTransform: "none",
  fontSize: { xs: "12px", md: "16px" },
  fontFamily: "Inter",
  "&:hover": {
    color: (theme) => theme.palette.primary.light,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: (theme) => theme.palette.primary.main,
    fontWeight: "700",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
};

const ProductTabNav = ({ currentTab, handleChange }) => (
  <Box>
    <Tabs
      value={currentTab}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="inherit"
      sx={{
        marginBottom: 2,
        "& .MuiTabs-indicator": {
          backgroundColor: (theme) => theme.palette.primary.main,
          height: 3,
        },
      }}
    >
      <Tab component={"span"} label="All Products" sx={tabStyle} />
      <Tab component={"span"} label="Product Format" sx={tabStyle} />
      <Tab component={"span"} label="Purchase Units" sx={tabStyle} />
      <Tab component={"span"} label="Training Details" sx={tabStyle} />
    </Tabs>
  </Box>
);

export default ProductTabNav;
