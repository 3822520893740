import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import Store from "../../../Store/Store.js";
import NewEstablishmentModal from "../../Modals/NewEstablishmentModal/index.js";
import { createEstablishment } from "../../../clients/establishmentsClient.js";
import { Box, Modal, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { modalBoxStyle } from "../../Modals/styles.js";
import inventoryClient from "../../../clients/inventoryClient.js";
import { useQueryClient } from "react-query";

const emptyEst = {
  name: "",
  address: "",
  city: "",
  state: "",
  website: "",
};

const EstablishmentPicker = ({ handleChangeEstablishment }) => {
  const store = useContext(Store);
  const [current, setCurrent] = useState({});
  const [options, setOptions] = useState([]);
  const [anchor, setAnchor] = React.useState(null);
  const toggle = () => setModal(!modal) + setDisplaySuccessMessage(false);
  const [modal, setModal] = useState(false);
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false);
  const [validationError, toggleValidationError] = useState(false);
  const [newEstablishment, updateNewEstablishment] = useState(emptyEst);
  const [isProcessingNewEst, toggleIsProcessingNewEst] = useState(false);
  const open = Boolean(anchor);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleSelect = async (establishment) => {
    setAnchor(null);
    await handleChangeEstablishment(establishment.id);
    navigate(`/spec/${establishment.id}/dashboard`);
    setAnchor(!anchor);
  };

  const handleCreationUpdate = (prop, value) => {
    const estCopy = Object.assign({}, newEstablishment);
    estCopy[prop] = value;
    updateNewEstablishment(estCopy);
  };

  const handleCreateEstablishment = async (newEst) => {
    try {
      toggleIsProcessingNewEst(true);
      const response = await createEstablishment(newEst);
      await inventoryClient.seedInventory(
        response.id,
        newEst.inventorySettings
      );
      queryClient.invalidateQueries({
        queryKey: [
          "my-memberships"
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "my-memberships"
        ],
      });
      store.updateCurrentEstablishment(response);
      store.updateEstablishmentOptions([...store.establishments, response]);
      toggleIsProcessingNewEst(false);
      navigate(`/spec/${response.id}/dashboard`);
      handleModalToggle();
      setAnchor(null);
      setAnchor(null);
    } catch (err) {
      toggleIsProcessingNewEst(true);
      console.log(err);
    }
  };

  const handleModalToggle = () => {
    updateNewEstablishment(emptyEst);
    toggleValidationError(false);
    toggle();
  };

  useEffect(() => {
    setCurrent(store.currentEstablishment);
    setOptions(
      store.establishments.filter((x) => x.id != store.currentEstablishment?.id)
    );
  }, [store.currentEstablishment]);

  return (
    <>
      <Button
        id="current-establishment-button"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          backgroundColor: "#000000",
          color: "#fff",
          border: "2px solid",
          borderColor: (theme) => theme.palette.terrain[800],
          borderRadius: 1,
          marginRight: 4,
          "&:hover": {
            background: "none",
          },
        }}
        disableElevation
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              "&:hover": {
                color: (theme) => theme.palette.primary[100],
              },
            }}
          />
        }
      >
        <Typography
          noWrap
          variant="h5"
          sx={{
            textAlign: "left",
            "&:hover": {
              color: (theme) => theme.palette.primary[100],
            },
          }}
        >
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              minWidth: "160px",
              maxWidth: "160px",
              textTransform: "none",
            }}
          >
            {current?.name}
          </div>
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={open}
        onClose={() => setAnchor(null)}
        className="menuToRight"
        MenuListProps={{
          sx: {
            background: (theme) => theme.palette.terrain[50],
          },
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            maxWidth: "300px",
            transform: "translateX(-300px) translateY(10px)",
            background: (theme) => theme.palette.terrain[50],
          },
        }}
      >
        {
          <MenuItem
            sx={{
              color: "#E78271",
              ":hover": {
                backgroundColor: (theme) => theme.palette.terrain["02"],
              },
            }}
          >
            {" "}
            {<CheckCircleIcon sx={{ marginRight: "10px" }} />}{" "}
            <Typography component="div" variant="body2" noWrap>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minWidth: "180px",
                  maxWidth: "180px",
                }}
              >
                {store.currentEstablishment.name}
              </div>
            </Typography>
          </MenuItem>
        }
        {options.map((x) => (
          <MenuItem
            sx={{
              color: (theme) => theme.palette.terrain[900],
              marginLeft: "35px",
              ":hover": {
                backgroundColor: (theme) => theme.palette.terrain["02"],
                fontWeight: 700,
              },
            }}
            key={x?.id}
            onClick={() => handleSelect(x)}
          >
            <Typography component="div" variant="body2" noWrap>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minWidth: "180px",
                  maxWidth: "180px",
                }}
              >
                {x?.name}
              </div>
            </Typography>
          </MenuItem>
        ))}
        {
          <MenuItem
            sx={{
              color: (theme) => theme.palette.terrain[900],
              ":hover": {
                backgroundColor: (theme) => theme.palette.terrain["02"],
                fontWeight: 700,
              },
            }}
            onClick={() => toggle()}
          >
            {" "}
            {<AddIcon sx={{ marginRight: "10px" }} />} Create New Establishment
          </MenuItem>
        }
      </Menu>

      <Modal
        open={modal}
        onClose={toggle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {displaySuccessMessage ? (
          <Box sx={modalBoxStyle}>
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <FontAwesomeIcon
                color="green"
                style={{ padding: 10 }}
                icon={faCheckCircle}
                size="7x"
              />
              <h4>Establishment Created!</h4>
            </div>
          </Box>
        ) : (
          <Box>
            <NewEstablishmentModal
              showCreateNew={modal}
              handleCreateEstablishment={handleCreateEstablishment}
              handleCreationUpdate={handleCreationUpdate}
              validationError={validationError}
              newEstablishment={newEstablishment}
              handleModalToggle={handleModalToggle}
              loading={isProcessingNewEst}
            />
          </Box>
        )}
      </Modal>
    </>
  );
};

export default EstablishmentPicker;
