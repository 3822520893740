import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LiveEditErrorModal from "../../components/Modals/LiveEditErrorModal";
import AddIcon from "@mui/icons-material/Add";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import TableViewOutlinedIcon from "@mui/icons-material/TableViewOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CreateInvoiceModal from "../../components/Modals/CreateInvoiceModal";
import CostBreakdown from "./components/CostBreakdown";
// import RecipePricing from "./components/RecipePricing";

const CostAnalysis = () => {
  const [errorModalOpen, toggleErrorModalOpen] = useState(false);
  const [invoiceModalOpen, toggleInvoiceModalOpen] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "2440px",
          padding: 4,
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            maxWidth: "2440px",
          }}
        >
          <>
            <Grid
              container
              sx={{
                maxWidth: "2440px",
                marginBottom: 6,
                display: "flex",
                justifyContent: "space-between",
                marginTop: 6,
              }}
            >
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: { xs: 20, sm: 20, md: 28 } }}
                >
                  Cost Analysis
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                  marginTop: { xs: 4, md: 0 },
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    padding: 4,
                    height: "40px",
                  }}
                  onClick={() => toggleInvoiceModalOpen(true)}
                >
                  <AddIcon sx={{ fontSize: { xs: 14, sm: 14, md: 20 } }} />
                  <Typography
                    variant="smallButton"
                    sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
                  >
                    Create New
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {/* Search Bar */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TextField
                size="small"
                fullWidth
                id="productSearch"
                inputProps={{
                  sx: {
                    marginBottom: 1,
                  },
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.pure.white,
                  borderRadius: 1,
                }}
                label={
                  <>
                    <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                    Search Products...
                  </>
                }
              />
            </Grid>
            <Box sx={{ width: "100%", marginTop: 6 }}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                select
                InputLabelProps={{ shrink: "true" }}
                sx={{
                  width: "240px",
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  backgroundColor: (theme) => theme.palette.pure.white,
                  borderRadius: 1,
                }}
              >
                <MenuItem value="Lipman">Recipe Pricing</MenuItem>
                <MenuItem value="Ice Labs">Wine Pricing</MenuItem>
              </TextField>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <TableViewOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Group</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <TuneOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Filter</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 2,
                  border: "2px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                }}
              >
                <FilterListOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                <Typography variant="largeButton">Sort</Typography>
              </Button>
            </Box>
            <CostBreakdown />
            {/* <RecipePricing /> */}
          </>
        </Grid>
        <CreateInvoiceModal
          isOpen={invoiceModalOpen}
          toggleModalOpen={toggleInvoiceModalOpen}
          buttonText="Create Invoice"
          headerText="Create New Invoice"
        />
        <LiveEditErrorModal
          isOpen={errorModalOpen}
          toggleErrorModalOpen={toggleErrorModalOpen}
          bodyText="Are you sure you want to navigate back? You will lose any unsaved information."
          headerText="Hold on..."
          buttonText="Go Back"
        />
        <Box>
          <Snackbar
            open={alertSuccess ? true : false}
            onClose={() => setAlertSuccess(undefined)}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success" variant="filled">
              {alertSuccess}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorMessage ? true : false}
            onClose={() => setErrorMessage(undefined)}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error" variant="filled">
              {errorMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </>
  );
};

export default CostAnalysis;
