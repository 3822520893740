import { productSizeTypes } from "../clients/inventoryObjects";
const localExtractSizeAbbreviation = (size) => {
  let abbreviation = "";

  if (size) {
    if (size?.type === productSizeTypes.Arbitrary) {
      abbreviation = "ct";
    } else {
      if (size?.isKegSize) {
        return size?.unitOfMeasurement?.kegAbbreviation || "";
      }
      abbreviation = size.unitOfMeasurement?.abbreviation || "";
    }
  }

  return abbreviation;
};

const utilFunctions = {
  sortByNameAlphabetically: (array) => {
    return array.sort(function (a, b) {
      var textA = a.name.toUpperCase();
      var textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  },
  sortByNameReverseAlphabetically: (array) => {
    return array.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
    });
},
  parseJwt: (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  },
  capitalizeString: (text) => {
    if (!text) {
      return "";
    }
    return text
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  },
  getNextDayInt: (currentDay) => {
    if (parseInt(currentDay) === 6) {
      return 0;
    }

    return parseInt(currentDay) + 1;
  },
  convertCentsToLargerFormatCurrency: (cents, locale, currency) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currency,
    })
      .formatToParts(cents / 100)
      .map((p) => (p.type != "literal" && p.type != "currency" ? p.value : ""))
      .join("");
  },
  convertCurrencyToCents: (value) => {
    value = (value + "").replace(/[^\d.-]/g, "");
    if (value && value.includes(".")) {
      value = value.substring(0, value.indexOf(".") + 3);
    }

    return value ? Math.round(parseFloat(value) * 100) : 0;
  },
  bankersRound: (num, decimalPlaces) => {
    var d = decimalPlaces || 0;
    var m = Math.pow(10, d);
    var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    var i = Math.floor(n),
      f = n - i;
    var e = 1e-8; // Allow for rounding errors in f
    var r =
      f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
  },
  blurInput: (e) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.target.blur();
    }
  },
  highlightInputOnFocus: (e) => {
    e.target.select();
  },
  isNumeric: function isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  },
  extractFormatSizingName: (productFormat) => {
    let sizeLabel = "(Missing Format Sizing)";
    const defaultSize = productFormat?.sizes?.find((x) => x.isDefaultSize);
    if (!defaultSize) {
      return sizeLabel;
    }
    if (defaultSize?.isKegSize) {
      return defaultSize.unitOfMeasurement?.kegAbbreviation || "";
    }

    let abbreviation = localExtractSizeAbbreviation(defaultSize);

    if (
      defaultSize?.unitOfMeasurement !== null ||
      defaultSize?.type === productSizeTypes.Arbitrary
    ) {
      sizeLabel = `${
        defaultSize.size === null ? "" : defaultSize.size
      } ${abbreviation}`;
    }

    return sizeLabel;
  },
  extractSizeAbbreviation: localExtractSizeAbbreviation,
  extractRecipeQuantityAbbreviation: (recipeQuantity) => {
    let abbreviation = "";

    if (recipeQuantity) {
      if (recipeQuantity?.type === productSizeTypes.Arbitrary) {
        abbreviation = "ct";
      } else {
        abbreviation = recipeQuantity?.unitOfMeasurement?.abbreviation || "";
      }
    }

    return abbreviation;
  },
  extractServingSizeAbbreviation: (servingSize) => {
    let abbreviation = "";

    if (servingSize) {
      if (servingSize?.type === productSizeTypes.Arbitrary) {
        abbreviation = "ct";
      } else {
        abbreviation = servingSize?.unitOfMeasurement?.abbreviation || "";
      }
    }

    return abbreviation;
  },
  extractCartItemSizeName: (cartItem) => {
    let sizeLabel = "(Missing Format Sizing)";
    const defaultSize = cartItem?.productFormatSizes?.find(
      (x) => x.isDefaultSize
    );
    if (!defaultSize) {
      return sizeLabel;
    }

    if (defaultSize?.isKegSize) {
      return defaultSize.unitOfMeasurement?.kegAbbreviation || "";
    }

    let abbreviation = localExtractSizeAbbreviation(defaultSize);

    if (
      defaultSize?.unitOfMeasurement !== null ||
      defaultSize?.type === productSizeTypes.Arbitrary
    ) {
      sizeLabel = `${
        defaultSize.size === null ? "" : defaultSize.size
      } ${abbreviation}`;
    }

    return sizeLabel;
  },
  extractRecipeYieldSizeDisplay: (recipe) => {
    let sizeLabel = "";
    const defaultSize = recipe.yield?.find((x) => x.isDefaultSize);
    if (!defaultSize) {
      return sizeLabel;
    }

    if (defaultSize?.isKegSize) {
      return defaultSize.unitOfMeasurement?.kegAbbreviation || "";
    }

    let abbreviation = localExtractSizeAbbreviation(defaultSize);

    if (
      defaultSize?.unitOfMeasurement !== null ||
      defaultSize?.type === productSizeTypes.Arbitrary
    ) {
      sizeLabel = `${
        defaultSize.size === null ? "" : defaultSize.size
      } ${abbreviation}`;
    }

    return sizeLabel;
  },
};

export default utilFunctions;
