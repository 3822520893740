import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RecipeIngredientRow from "./RecipeIngredientRow";
import YieldModal from "../../../components/Modals/YieldModal";
import IngredientQuantityModal from "../../../components/Modals/IngredientQuantityModal";
import utilFunctions from "../../../utilities/utilFunctions";
import { cloneDeep } from "lodash";
import Store from "../../../Store/Store";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import YieldToggle from "./YieldToggle";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import EditRecipeModal from "../../../components/Modals/EditRecipeModal";
import { recipeIngredientTypes } from "../../../clients/inventoryObjects";
import DescriptionToolTip from "../../../components/DescriptionToolTip";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";
import SubscriptionCellLock from "../../../components/SubscriptionCellLock";
import ImageControl from "../../../components/ImageControl";

const RecipeForm = ({
  recipe,
  establishmentUoMs,
  productLibrary,
  productLibraryMap,
  establishmentRecipes,
  subscriptionState,
}) => {
  const store = useContext(Store);
  const [yieldModalOpen, toggleYieldModalOpen] = useState(false);
  const [quantityModalOpen, toggleQuantityModalOpen] = useState(false);
  const [editRecipeModalOpen, toggleEditRecipeModalOpen] = useState(false);
  const [recipeCopy, setRecipeCopy] = useState({});
  const [laborCostDisplay, setLaborCostDisplay] = useState(0);
  const [laborDurationDisplay, setLaborDurationDisplay] = useState(null);
  const [laborDurationInvalid, toggleLaborDurationInvalid] = useState(null);
  const [isImageControlOpen, toggleIsImageControlOpen] = useState(false);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [ingredientForQuantityModal, setIngredientForQuantityModal] =
    useState(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    setRecipeCopy(cloneDeep(recipe));

    if (subscriptionState?.isActive) {
      setLaborCostDisplay(
        utilFunctions.convertCentsToLargerFormatCurrency(
          recipe?.laborCost?.wage?.amount,
          locale,
          currency
        )
      );

      if (
        recipe?.laborCost?.hourDuration === 0 &&
        recipe?.laborCost?.minuteDuration === 0
      ) {
        setLaborDurationDisplay(null);
      } else {
        convertLaborToDisplay(
          recipe?.laborCost?.hourDuration,
          recipe?.laborCost?.minuteDuration
        );
      }
    }
  }, [recipe, subscriptionState]);

  const updateLocalRecipeCopy = (property, newValue) => {
    const updatedRecipe = cloneDeep(recipeCopy);
    updatedRecipe[property] = newValue;
    setRecipeCopy(updatedRecipe);
  };

  const recipeMutation = useMutation(inventoryClient.patchRecipe, {
    onSuccess: (result, variables) => {
      // because we don't know the new image url until the server tells us, we can't
      // optimistically update that before the call, so we have a special check
      // to set the image url once its handed back
      if (variables.imageUrlUpdate !== undefined) {
        const updatedRecipe = cloneDeep(recipeCopy);
        updatedRecipe.imageUrl = result.recipe.imageUrl;
        setRecipeCopy(updatedRecipe);
      }

      if (variables.laborCostUpdate !== undefined) {
        queryClient.invalidateQueries({
          queryKey: [
            "recipes",
            {
              establishmentId: store.currentEstablishment?.id,
              recipeId: recipe.id,
            },
          ],
        });
        queryClient.refetchQueries({
          queryKey: [
            "recipes",
            {
              establishmentId: store.currentEstablishment?.id,
              recipeId: recipe.id,
            },
          ],
        });
      }
    },
  });

  const ingredientMutation = useMutation(inventoryClient.patchIngredient, {
    onSuccess: (result) => {
      const updatedRecipe = cloneDeep(recipeCopy);
      updatedRecipe.ingredients = updatedRecipe.ingredients.map((x) => {
        if (x.id === result.ingredient.id) {
          return result.ingredient;
        } else {
          return x;
        }
      });
      setRecipeCopy(updatedRecipe);
    },
  });

  const addIngredientMutation = useMutation(inventoryClient.addIngredient, {
    onSuccess: (result) => {
      const updatedRecipe = cloneDeep(recipeCopy);
      updatedRecipe.ingredients.push(result.ingredient);
      setRecipeCopy(updatedRecipe);
    },
  });

  const handleUpdateRecipe = async (property, newValue) => {
    const updatedRecipe = cloneDeep(recipeCopy);
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      recipeId: recipe.id,
    };

    // we use the value already set on the copy if no value is provided.
    // this is because we have certain fields that are updated dynamically
    // as the user types locally, and then we want to update the server in one shot
    updatedRecipe[property] = newValue || recipeCopy[property];
    payload[`${property}Update`] = { value: newValue || recipeCopy[property] };
    await recipeMutation.mutateAsync(payload);

    // image url gets custom created by server, so we don't know the url. We rely on OnSuccess
    // function of mutation to update this value
    if (property !== "imageUrl") {
      setRecipeCopy(updatedRecipe);
    }
  };

  const handleUpdateIngredient = async (property, newValue, ingredientId) => {
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      recipeId: recipe.id,
      ingredientId: ingredientId,
    };

    payload[`${property}Update`] = { value: newValue };
    await ingredientMutation.mutateAsync(payload);
  };

  const handleAddIngredient = async () => {
    addIngredientMutation.mutate({
      recipeId: recipeCopy.id,
      establishmentId: store.currentEstablishment?.id,
    });
  };

  const handleToggleQuantityModal = (shouldOpenModal, ingredient) => {
    setIngredientForQuantityModal(ingredient);
    toggleQuantityModalOpen(shouldOpenModal);
  };

  const handleIngredientUpdateCallback = (ingredient) => {
    const updatedRecipe = cloneDeep(recipeCopy);
    updatedRecipe.ingredients = updatedRecipe.ingredients.map((x) => {
      if (x.id === ingredient.id) {
        return ingredient;
      } else {
        return x;
      }
    });
    setRecipeCopy(updatedRecipe);
  };

  const handleDeleteIngredientCallback = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "recipes",
        {
          establishmentId: store.currentEstablishment?.id,
          recipeId: recipe.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "recipes",
        {
          establishmentId: store.currentEstablishment?.id,
          recipeId: recipe.id,
        },
      ],
    });
  };

  const handleLaborCostUpdate = async () => {
    const centsValue = utilFunctions.convertCurrencyToCents(laborCostDisplay);
    const newLaborCost = cloneDeep(recipeCopy.laborCost);
    newLaborCost.wage.amount = centsValue;
    await handleUpdateRecipe("laborCost", newLaborCost);
    setLaborCostDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        centsValue,
        locale,
        currency
      )
    );
  };

  const handleUpdateLaborDuration = async () => {
    toggleLaborDurationInvalid(false);

    const isValidFormat = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d))$/.test(
      laborDurationDisplay
    );

    if (!isValidFormat) {
      toggleLaborDurationInvalid(true);
      return;
    }

    const updatedLabor = cloneDeep(recipeCopy.laborCost);

    if (laborDurationDisplay.includes(":")) {
      const splitValue = laborDurationDisplay.split(":");
      updatedLabor.hourDuration = splitValue[0];
      updatedLabor.minuteDuration = splitValue[1];
    } else {
      updatedLabor.hourDuration = 0;
      updatedLabor.minuteDuration = laborDurationDisplay;
    }

    await handleUpdateRecipe("laborCost", updatedLabor);
    setLaborDurationDisplay(
      convertLaborToDisplay(
        Number(updatedLabor.hourDuration),
        Number(updatedLabor.minuteDuration)
      )
    );
  };

  const convertLaborToDisplay = (hour, minute) => {
    let hourStamp = "0";
    let minuteStamp = "0";

    if (hour < 10) {
      hourStamp = hourStamp + hour.toString();
    } else {
      hourStamp = hour.toString();
    }

    if (minute < 10) {
      minuteStamp = minuteStamp + minute.toString();
    } else {
      minuteStamp = minute.toString();
    }

    setLaborDurationDisplay(hourStamp + ":" + minuteStamp);
  };

  const imageControlCallback = async (photoLocation) => {
    await handleUpdateRecipe("imageUrl", photoLocation);
    toggleIsImageControlOpen(false);
  };

  const calculateTotalCost = () => {
    let total = 0;

    recipeCopy?.ingredients?.forEach((x) => {
      if (x.ingredientCost?.isValid && x.includeInCosting) {
        total += x.ingredientCost?.result?.amount || 0;
        total += x.laborCost?.result?.amount || 0;
      }
    });

    total += recipeCopy?.laborCost?.cost?.amount;

    const formatted = utilFunctions.convertCentsToLargerFormatCurrency(
      total,
      locale,
      currency
    );
    return currencySymbol + formatted.toString();
  };

  const tableCellStyle = {
    padding: 0,
    border: "1px solid",
    borderBottom: "none",
    borderColor: (theme) => theme.palette.terrain[400],
    backgroundColor: (theme) => theme.palette.pure.white,
  };

  const tableHeadStyle = {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    background: (theme) => theme.palette.terrain[300],
    height: "24px",
    border: "1px solid",
    borderBottom: "none",
    borderColor: (theme) => theme.palette.terrain[400],
    borderTop: "none",
    lineHeight: 1.2,
    fontSize: 10,
    fontWeight: 600,
  };

  const calculateIngredientCostTotal = () => {
    let total = 0;

    recipeCopy?.ingredients?.forEach((x) => {
      if (x.ingredientCost?.isValid && x.includeInCosting) {
        total += x.ingredientCost?.result?.amount || 0;
      }
    });

    const formatted = utilFunctions.convertCentsToLargerFormatCurrency(
      total,
      locale,
      currency
    );
    return currencySymbol + formatted.toString();
  };

  const calculateLaborCostTotal = () => {
    let total = 0;

    recipeCopy?.ingredients?.forEach((x) => {
      if (
        x.type === recipeIngredientTypes.Recipe &&
        x.laborCost?.result?.amount !== -1
      ) {
        total += x.laborCost?.result?.amount || 0;
      }
    });

    const formatted = utilFunctions.convertCentsToLargerFormatCurrency(
      total,
      locale,
      currency
    );
    return currencySymbol + formatted.toString();
  };

  const filteredRecipeOptions = establishmentRecipes?.filter(
    (x) => x.id !== recipeCopy.id
  );
  return (
    <Box>
      <Box sx={{ marginTop: 4, marginBottom: 2 }}>
        <Grid
          container
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Typography variant="h2">
                  {utilFunctions.capitalizeString(recipeCopy.name || "")}
                </Typography>
              </Grid>
              <Grid item sx={{ marginLeft: 4 }}>
                <IconButton
                  onClick={() => toggleEditRecipeModalOpen(true)}
                  sx={{
                    border: "2px solid",
                    borderColor: (theme) => theme.palette.primary[800],
                    width: "32px",
                    height: "32px",
                  }}
                >
                  <EditOutlinedIcon sx={{ fontSize: 14 }} />{" "}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "140px",
            height: "32px",
            borderRadius: 1,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            backgroundColor: (theme) => theme.palette.pure.white,
          }}
        >
          <Typography variant="body1">Recipe Status: </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.info.main,
              marginLeft: 1,
            }}
          >
            {recipeCopy.isActive ? "Active" : "Inactive"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "140px",
            height: "32px",
            borderRadius: 1,
            border: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            backgroundColor: (theme) => theme.palette.pure.white,
            marginLeft: 2,
          }}
        >
          <Typography variant="body1">Show to Staff: </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.info.main,
              marginLeft: 1,
            }}
          >
            {recipeCopy.isSharedWithStaff ? "Visible" : "Hidden"}
          </Typography>
        </Box>
      </Box>

      {/* Base Details */}

      <Box sx={{ marginTop: 6 }}>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="h3">Base Details</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 4,
            width: "100%",
            height: { xs: "140px", md: "88px" },
            borderRadius: 4,
            backgroundColor: (theme) => theme.palette.pure.white,
            border: "2px solid",
            borderColor: (theme) => theme.palette.terrain[300],
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "56px",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 1,
                  padding: 2,
                }}
              >
                {subscriptionState?.isActive ? (
                  <>
                    <TextField
                      size="small"
                      label="Labor Cost (Wage p/h)"
                      placeholder="$0.00"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                        inputMode: "numeric",
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencySymbol}
                          </InputAdornment>
                        ),
                      }}
                      sx={{ width: "50%" }}
                      value={laborCostDisplay}
                      onChange={(e) => setLaborCostDisplay(e.target.value)}
                      onBlur={handleLaborCostUpdate}
                      onKeyDown={utilFunctions.blurInput}
                      onFocus={utilFunctions.highlightInputOnFocus}
                    />
                    <TextField
                      size="small"
                      label="Duration"
                      placeholder="hh:mm"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "50%", marginLeft: 2 }}
                      value={laborDurationDisplay}
                      onChange={(e) => setLaborDurationDisplay(e.target.value)}
                      onBlur={handleUpdateLaborDuration}
                      error={laborDurationInvalid}
                    />
                  </>
                ) : (
                  <SubscriptionCellLock />
                )}
              </Box>
            </Grid>
            <Grid item xs={3} md={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "56px",
                  width: "100%",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 1,
                  padding: 2,
                }}
              >
                <YieldToggle
                  recipe={recipeCopy}
                  toggleYieldModalOpen={toggleYieldModalOpen}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={9}
              md={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "70%",
                  height: "56px",
                  backgroundColor: (theme) => theme.palette.terrain[50],
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 1,
                  padding: 2,
                  marginRight: 4,
                }}
              >
                <Grid item>
                  <Typography variant="body1">Total Cost:</Typography>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "84px",
                      height: "40px",
                      borderRadius: 1,
                      backgroundColor: (theme) => theme.palette.terrain[200],
                    }}
                  >
                    {subscriptionState?.isActive ? (
                      <Typography variant="body2">
                        {calculateTotalCost(recipeCopy)}
                      </Typography>
                    ) : (
                      <SubscriptionCellLock />
                    )}
                  </Box>
                </Grid>
              </Box>
              <ImageControl
                size="small"
                imageSrc={recipeCopy.imageUrl}
                imageControlCallback={imageControlCallback}
                isImageControlOpen={isImageControlOpen}
                toggleIsImageControlOpen={toggleIsImageControlOpen}
                isLoading={recipeMutation?.isLoading}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Ingredients Section */}

      <Box sx={{ marginTop: 8 }}>
        <Box sx={{ marginBottom: 4 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Typography variant="h3">Ingredients</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleAddIngredient}
                disabled={addIngredientMutation.isLoading}
              >
                <Typography variant="largeButton">+ Add Ingredient</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Grid
          item
          sx={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <TableContainer
            sx={{
              overflow: "scroll",
              borderRadius: 2,
              border: "1px solid",
              borderColor: (theme) => theme.palette.terrain[400],
            }}
          >
            <Table
              sx={{ width: "100%", overflow: "scroll" }}
              aria-label="customized table"
            >
              <TableHead sx={{ height: "24px" }}>
                <TableRow sx={{ height: 24 }}>
                  {/* DRAG ICON HEADER */}
                  {/* <TableCell
                    sx={{
                      borderLeft: "none",
                      borderBottom: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[400],
                      background: (theme) => theme.palette.terrain[300],
                      padding: 0,
                      width: "32px !important",
                    }}
                  ></TableCell> */}
                  <TableCell
                    sx={[tableHeadStyle, { width: "80px", borderLeft: "none" }]}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Quantity
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Quantity"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                The amount of product or ingredient in the
                                recipe.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={[tableHeadStyle, { width: "310px" }]}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Ingredient Name
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Ingredient Name"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                The name of product or ingredient in the recipe.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={[tableHeadStyle, { width: "140px" }]}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Display Name
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Display Name"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                How the ingredient line will display to staff
                                users in the Mobile App.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Product Format
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Product Format"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                The name and unit size of the product. This is
                                necessary to accurately calculate the cost of
                                the product or ingredient.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      { borderRight: "none", width: "100px" },
                    ]}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Purchase Unit
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Purchase Unit"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                Specify which Product Format packaging this
                                product or ingredient comes from. This is an
                                important distinction for costing. For example
                                it is important to distinguish between 1 bottle
                                of something vs. 1 case of something.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        width: "56px !important",
                        padding: 0,
                      },
                    ]}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      Training
                      <Box component="div" sx={{ marginRight: 0 }}>
                        <DescriptionToolTip
                          header="Training"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                Toggles whether the ingredient line will be
                                hidden from staff users in the Mobile App.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        width: "56px !important",
                        padding: 0,
                      },
                    ]}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      Costing
                      <Box component="div" sx={{ marginRight: 0 }}>
                        <DescriptionToolTip
                          header="Costing"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                Toggles whether the ingredient line will count
                                towards the cost of the entire recipe.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        width: "56px !important",
                        textAlign: "center",
                        padding: 0,
                      },
                    ]}
                  >
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: 1,
                        paddingRight: 1,
                      }}
                    >
                      Garnish
                      <Box component="div" sx={{ marginRight: 0 }}>
                        <DescriptionToolTip
                          header="Garnish"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                Toggles whether the ingredient line is a
                                garnish, thus appearing in the <q>Garnish</q>{" "}
                                section of the Mobile App when viewing as a
                                staff user.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={[tableHeadStyle, { width: "84px" }]}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Labor Cost
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Labor Cost"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                If the ingredient is another recipe, the
                                associated labor cost (as a portion of the
                                quantity on the ingredeint line) will show here.
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell sx={[tableHeadStyle, { width: "84px" }]}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Ingredient $
                      <Box component="div" sx={{ marginRight: -3 }}>
                        <DescriptionToolTip
                          header="Ingredient Cost"
                          description={
                            <Box component="div">
                              <Box sx={{ marginTop: 1 }}>
                                The cost of the ingredient line, either the cost
                                of the product or only the ingredient cost of
                                another recipe (as a portion of the quantity).
                              </Box>
                            </Box>
                          }
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      { width: "57px", borderRight: "none" },
                    ]}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recipeCopy.ingredients?.map((x) => (
                  <RecipeIngredientRow
                    key={x.id}
                    openQuantityModal={handleToggleQuantityModal}
                    ingredient={x}
                    productLibrary={productLibrary}
                    productLibraryMap={productLibraryMap}
                    establishmentRecipes={filteredRecipeOptions}
                    updateIngredientCallback={handleIngredientUpdateCallback}
                    deleteIngredientCallback={handleDeleteIngredientCallback}
                    tableCellStyle={tableCellStyle}
                    subscriptionState={subscriptionState}
                  />
                ))}
                <TableRow>
                  {/* COLSPAN NEEDS TO BE CHANGED TO 9 WHEN WE IMPLEMENT THE DRAG
                  ICON */}
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        borderLeft: "none",
                        borderTop: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[400],
                      },
                    ]}
                    colSpan={8}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        borderTop: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[400],
                      },
                    ]}
                  >
                    {subscriptionState.isActive ? (
                      calculateLaborCostTotal()
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        borderTop: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[400],
                      },
                    ]}
                  >
                    {subscriptionState.isActive ? (
                      calculateIngredientCostTotal()
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell
                    sx={[
                      tableHeadStyle,
                      {
                        borderRight: "none",
                        borderTop: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[400],
                      },
                    ]}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>

      {/* Training Details */}

      <Box sx={{ marginTop: 8 }}>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="h3">Training Details</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={[
                tableHeadStyle,
                {
                  width: "33%",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid",
                  borderRight: "none",
                  borderColor: (theme) => theme.palette.terrain[400],
                  borderTopLeftRadius: 8,
                },
              ]}
            >
              Description
            </Box>
            <Box
              sx={[
                tableHeadStyle,
                {
                  width: "34%",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                },
              ]}
            >
              Tasting Notes
            </Box>
            <Box
              sx={[
                tableHeadStyle,
                {
                  width: "33%",
                  borderRight: "none",
                  borderTopRightRadius: 8,
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid",
                  borderLeft: "none",
                  borderColor: (theme) => theme.palette.terrain[400],
                },
              ]}
            >
              Directions
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={[
                tableCellStyle,
                {
                  width: "33%",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  borderTop: "none",
                  borderRight: "none",
                  borderBottomLeftRadius: 8,
                },
              ]}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  height: "100%",
                  backgroundColor: (theme) => theme.palette.pure.white,
                  borderBottomLeftRadius: 8,
                }}
              >
                <TextField
                  variant="filled"
                  multiline
                  fullWidth
                  placeholder="Enter description"
                  autoComplete="off"
                  minRows={5}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: "100%",
                    borderRadius: 0,

                    "& .MuiFilledInput-root": {
                      background: (theme) => theme.palette.pure.white,
                      borderBottomLeftRadius: 8,
                    },
                  }}
                  inputProps={{ style: { marginTop: -20 } }}
                  InputProps={{
                    disableUnderline: true,
                    height: "100%",
                  }}
                  onChange={(e) =>
                    updateLocalRecipeCopy("description", e.target.value)
                  }
                  onBlur={() => handleUpdateRecipe("description")}
                  value={recipeCopy.description}
                />
              </Box>
            </Box>
            <Box
              sx={[
                tableCellStyle,
                {
                  width: "34%",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  borderTop: "none",
                },
              ]}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  height: "100%",
                  backgroundColor: (theme) => theme.palette.pure.white,
                }}
              >
                <TextField
                  variant="filled"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="Enter tasting notes"
                  minRows={5}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: "100%",
                    borderRadius: 0,
                    "& .MuiFilledInput-root": {
                      background: (theme) => theme.palette.pure.white,
                    },
                  }}
                  InputProps={{ disableUnderline: true, height: "100%" }}
                  inputProps={{ style: { marginTop: -20 } }}
                  onChange={(e) =>
                    updateLocalRecipeCopy("tastingNotes", e.target.value)
                  }
                  onBlur={() => handleUpdateRecipe("tastingNotes")}
                  value={recipeCopy.tastingNotes}
                />
              </Box>
            </Box>
            <Box
              sx={[
                tableCellStyle,
                {
                  width: "33%",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[400],
                  borderLeft: "none",
                  borderTop: "none",
                  borderBottomRightRadius: 8,
                },
              ]}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  height: "fit-content",
                  backgroundColor: (theme) => theme.palette.pure.white,
                  borderBottomRightRadius: 8,
                }}
              >
                <TextField
                  variant="filled"
                  multiline
                  fullWidth
                  autoComplete="off"
                  placeholder="Enter directions"
                  minRows={5}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: "100%",
                    borderRadius: 0,
                    "& .MuiFilledInput-root": {
                      background: (theme) => theme.palette.pure.white,
                      borderBottomRightRadius: 8,
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    height: "fit-content",
                  }}
                  inputProps={{ style: { marginTop: -20 } }}
                  onChange={(e) =>
                    updateLocalRecipeCopy("directions", e.target.value)
                  }
                  onBlur={() => handleUpdateRecipe("directions")}
                  value={recipeCopy.directions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <IngredientQuantityModal
        isOpen={quantityModalOpen}
        toggleModalOpen={handleToggleQuantityModal}
        ingredient={ingredientForQuantityModal}
        unitsOfMeasurement={establishmentUoMs}
        updateIngredient={handleUpdateIngredient}
        loading={recipeMutation.isLoading}
      />
      <YieldModal
        isOpen={yieldModalOpen}
        toggleModalOpen={toggleYieldModalOpen}
        recipeYields={recipe?.yield}
        updateRecipe={handleUpdateRecipe}
        unitsOfMeasurement={establishmentUoMs}
        isLoading={recipeMutation.isLoading}
      />
      <EditRecipeModal
        isOpen={editRecipeModalOpen}
        toggleModalOpen={toggleEditRecipeModalOpen}
        headerText="Edit Recipe"
        buttonText="Save Recipe"
        recipe={recipe}
      />
    </Box>
  );
};

export default RecipeForm;
