import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import s3Client from "../../../../clients/s3Client";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import useVendorCurrency from "../../../../hooks/useVendorCurrency";
import Store from "../../../../Store/Store";
import InvoiceProductCard from "../../InvoiceProductCard";
import InvoiceKegCard from "../../InvoiceKegCard";
import InvoiceMiscCard from "../../InvoiceMiscCard";
import { cloneDeep } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import utilFunctions from "../../../../utilities/utilFunctions";
import OrderTotalCostBadge from "../OrderTotalCostBadge";
import inventoryObjects from "../../../../clients/inventoryObjects";
import { successStyle } from "./style";
import AttachmentControl from "../../../../components/AttachmentControl";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ApproveInvoiceModal from "../../../../components/Modals/ApproveInvoiceModal";
import CreateInvoiceModal from "../../../../components/Modals/CreateInvoiceModal";
import SpecBackButton from "../../../../components/SpecBackButton";
import InvoiceStatusChip from "../../../../components/InvoiceStatusChip";
import { useNavigate, useParams } from "react-router-dom";
import CreateExpenseGroupModal from "../../../../components/Modals/CreateExpenseGroupModal";
import CreateExpenseSubgroupModal from "../../../../components/Modals/CreateExpenseSubgroupModal";
import useEstablishmentSettings from "../../../../hooks/useEstablishmentSettings";
var moment = require("moment");

const defaultStyle = {
  display: "flex",
  alignItems: "center",
  height: "40px",
  minHeight: "40px",
  width: "120px",
  backgroundColor: (theme) => theme.palette.terrain[200],
  borderRadius: 1,
  padding: 1,
};

const InvoiceDetailsForm = ({
  invoice,
  productLibrary,
  vendorMap,
  expenseGroups,
}) => {
  const store = useContext(Store);
  let { establishmentid } = useParams();
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { currencySymbol, currency, locale } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );
  const [invoiceCopy, setInvoiceCopy] = useState({});
  const [isExpenseGroupModalOpen, toggleIsExpenseGroupModalOpen] =
    useState(false);
  const [reconciledStatus, setReconciledStatus] = useState(
    inventoryObjects.invoiceStatuses.unreconciled
  );
  const [invoiceTotalDisplay, setInvoiceTotalDisplay] = useState(0);
  const [serverOrderTotal, setServerOrderTotal] = useState(0);
  const [approveInvoiceModalOpen, toggleApproveInvoiceModalOpen] =
    useState(false);
  const [editInvoiceModalOpen, toggleEditInvoiceModalOpen] = useState(false);
  const [invoiceApprovalPreview, setInvoiceApprovalPreview] = useState([]);
  const [incompleteLineItems, setIncompleteLineItems] = useState({});
  const [expenseGroupForSubgroupEdit, setExpenseGroupforSubgroupEdit] =
    useState({});
  const [isExpenseSubgroupModalOpen, toggleIsExpenseSubgroupModalOpen] =
    useState(false);

  const [additionalInformationText, setAdditionalInformationText] =
    useState("");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    setInvoiceCopy(cloneDeep(invoice));
    setReconciledStatus(invoice.status);
    setServerOrderTotal(invoice.orderTotal?.amount || 0);
    setInvoiceTotalDisplay(
      utilFunctions.convertCentsToLargerFormatCurrency(
        invoice.invoiceTotal.amount,
        locale,
        currency
      )
    );
    setAdditionalInformationText(invoice.additionalInformation);
  }, [invoice]);

  const deleteInvoiceAttachmentMutation = useMutation(inventoryClient.deleteInvoiceAttachment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "invoice",
          {
            establishmentId: store.currentEstablishment?.id,
            invoiceId: invoice.id
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "invoice",
          {
            establishmentId: store.currentEstablishment?.id,
            invoiceId: invoice.id
          },
        ],
      });
    },
  });

  const addInvoiceAttachmentMutation = useMutation(inventoryClient.addInvoiceAttachment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "invoice",
          {
            establishmentId: store.currentEstablishment?.id,
            invoiceId: invoice.id
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "invoice",
          {
            establishmentId: store.currentEstablishment?.id,
            invoiceId: invoice.id
          },
        ],
      });
    },
  });

  const attachmentControlCallback = async (attachmentLocation) => {
    const s3Response = await s3Client.uploadFileWithFileNamePreserved(attachmentLocation);
    const payload = {
      invoiceId: invoice.id,
      establishmentId: store.currentEstablishment?.id,
      url: s3Response
    }
    await addInvoiceAttachmentMutation.mutateAsync(payload);
  };

  const invoiceRootMutation = useMutation(inventoryClient.patchInvoice, {});

  const updateInvoice = async (property, newValue) => {
    const updatedInvoice = cloneDeep(invoiceCopy);
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      invoiceId: invoice.id,
    };

    if (property === "vendorId") {
      updatedInvoice.vendorIds = [newValue];
    } else {
      // we use the value already set on the copy if no value is provided.
      // this is because we have certain fields that are updated dynamically
      // as the user types locally, and then we want to update the server in one shot
      updatedInvoice[property] = newValue || invoiceCopy[property];
    }
    payload[`${property}Update`] = { value: newValue || invoiceCopy[property] };
    await invoiceRootMutation.mutateAsync(payload);

    if (property === "invoiceTotal") {
      setInvoiceTotalDisplay(
        utilFunctions.convertCentsToLargerFormatCurrency(
          newValue.amount,
          locale,
          currency
        )
      );

      queryClient.setQueryData(
        [
          "invoice",
          {
            establishmentId: store.currentEstablishment?.id,
            invoiceId: invoice.id,
          },
        ],
        { invoice: updatedInvoice }
      );
      attemptInvoiceReconciliation();
    }
  };

  const handleUpdateQueryCache = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "invoice",
        {
          establishmentId: store.currentEstablishment?.id,
          invoiceId: invoice.id,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "invoice",
        {
          establishmentId: store.currentEstablishment?.id,
          invoiceId: invoice.id,
        },
      ],
    });
  };

  const handleUpdateAdditionalInformationText = (e) => {
    setAdditionalInformationText(e.target.value);
  };

  const invoiceReconciliationMutation = useMutation(
    inventoryClient.reconcileInvoice,
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries({
          queryKey: [
            "invoices",
            {
              establishmentId: store.currentEstablishment?.id,
            },
          ],
        });

        handleUpdateInvoiceReconciliationStatus(result);
      },
    }
  );

  const attemptInvoiceReconciliation = () => {
    invoiceReconciliationMutation.mutate({
      invoiceId: invoice.id,
      establishmentId: store.currentEstablishment?.id,
    });
  };

  const handleUpdateInvoiceReconciliationStatus = (reconciliationResult) => {
    setReconciledStatus(reconciliationResult.invoiceStatus);
    setServerOrderTotal(reconciliationResult.orderTotal?.amount);
    setIncompleteLineItems(reconciliationResult.invalidLineItems || [])
  };

  const generateApprovalPreviewMutation = useMutation(
    inventoryClient.generateInvoiceApprovalPreview,
    {
      onSuccess: (result) => {
        setInvoiceApprovalPreview(result);
        toggleApproveInvoiceModalOpen(true);
      },
    }
  );

  const approveInvoiceMutation = useMutation(inventoryClient.approveInvoice, {
    onSuccess: handleUpdateQueryCache,
  });

  const handleCompleteApproveInvoice = (purchaseUnitsToExclude) => {
    const payload = {
      establishmentId: establishmentid,
      invoiceId: invoice.id,
      purchaseUnitUpdatesToSkip: purchaseUnitsToExclude,
    };

    approveInvoiceMutation.mutate(payload);
  };

  const handleGenerateApproval = () => {
    const payload = {
      establishmentId: establishmentid,
      invoiceId: invoice.id,
    };

    generateApprovalPreviewMutation.mutate(payload);
  };

  const handleBlurAdditionalInformation = () => {
    updateInvoice("additionalInformation", additionalInformationText);
  };

  const handleOpenSubgroupEditModal = (expenseGroupParent) => {
    setExpenseGroupforSubgroupEdit(expenseGroupParent);
    toggleIsExpenseSubgroupModalOpen(true);
  };

  return (
    <>
      <form>
        <Grid container direction="column" sx={{ width: "100%", marginTop: 4 }}>
          <Box>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid container direction="column">
                <Box>
                  <SpecBackButton
                    backText="Invoices"
                    onClick={() =>
                      navigate(`/spec/${establishmentid}/invoices`)
                    }
                  />
                </Box>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 6,
                  }}
                >
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography variant="h3" sx={{ marginLeft: 2 }}>
                        {moment(invoiceCopy.invoiceDate).format(
                          "MMMM DD, YYYY"
                        )}
                      </Typography>
                      <IconButton
                        sx={{
                          marginLeft: 2,
                          width: "32px",
                          height: "32px",
                          border: "2px solid",
                          borderColor: (theme) => theme.palette.primary[800],
                        }}
                        onClick={() => toggleEditInvoiceModalOpen(true)}
                      >
                        <EditOutlinedIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <AttachmentControl
                        invoice={invoice}
                        attachmentControlCallback={attachmentControlCallback}
                        deleteMutation={deleteInvoiceAttachmentMutation}
                        size="small"
                        disableCrop
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 1,
                      padding: 2,
                      height: "32px",
                      width: "fit-content",
                      borderRadius: 1,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[300],
                      backgroundColor: (theme) => theme.palette.pure.white,
                    }}
                  >
                    Vendor: {vendorMap[invoice.vendorIds[0]]?.name || "-"}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: 1,
                      padding: 2,
                      height: "32px",
                      width: "fit-content",
                      borderRadius: 1,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[300],
                      backgroundColor: (theme) => theme.palette.pure.white,
                    }}
                  >
                    <Typography variant="body1">Invoice No:</Typography>
                    <Typography variant="body2" sx={{ marginLeft: 2 }}>
                      {invoiceCopy.invoiceNumber}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Reconciliation Component */}
            <Grid
              item
              sx={{ marginTop: 4, display: "flex", alignItems: "center" }}
            >
              <Box
                sx={{
                  height: "72px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  backgroundColor: (theme) => theme.palette.pure.white,
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[200],
                  borderRadius: 2,
                }}
              >
                <Grid
                  direction="row"
                  container
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 2,
                  }}
                >
                  {/* Invoice Total Section */}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "56px",
                      width: "24%",
                      border: "1px solid",
                      borderRadius: 1,
                      borderColor: (theme) => theme.palette.terrain[300],
                      backgroundColor: (theme) => theme.palette.terrain[50],
                      padding: 2,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={6}>
                        <Typography variant="body1">Invoice Total:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          variant="filled"
                          fullWidth
                          placeholder="Invoice Total"
                          InputLabelProps={{ shrink: "true" }}
                          value={invoiceTotalDisplay}
                          onFocus={utilFunctions.highlightInputOnFocus}
                          onChange={(e) => {
                            setInvoiceTotalDisplay(e.target.value);
                          }}
                          onBlur={() =>
                            updateInvoice(
                              "invoiceTotal",
                              Object.assign({}, invoiceCopy.invoiceTotal, {
                                amount:
                                  utilFunctions.convertCurrencyToCents(
                                    invoiceTotalDisplay
                                  ),
                              })
                            )
                          }
                          onKeyDown={utilFunctions.blurInput}
                          InputProps={{
                            disableUnderline: true,
                            inputMode: "numeric",
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ marginBottom: 4 }}
                              >
                                {currencySymbol}
                              </InputAdornment>
                            ),
                            sx: {
                              "& input": {
                                textAlign: "right",
                                marginBottom: 4,
                              },
                            },
                          }}
                          sx={{
                            "& .MuiFilledInput-root": {
                              backgroundColor: (theme) =>
                                reconciledStatus ===
                                inventoryObjects.invoiceStatuses.reconciled
                                  ? theme.palette.success[50]
                                  : theme.palette.pure.white,
                              height: "40px !important",
                              border: "1px solid",
                              borderColor: (theme) =>
                                reconciledStatus ===
                                inventoryObjects.invoiceStatuses.reconciled
                                  ? theme.palette.success[500]
                                  : theme.palette.terrain[600],
                              borderRadius: 1,
                            },
                            "& .MuiFilledInput-root.Mui-focused": {
                              borderColor: (theme) =>
                                theme.palette.primary[800],
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Line Item Total Section */}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "56px",
                      width: "24%",
                      border: "1px solid",
                      borderRadius: 1,
                      borderColor: (theme) => theme.palette.terrain[300],
                      backgroundColor: (theme) => theme.palette.terrain[50],
                      padding: 2,
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <OrderTotalCostBadge
                        displayText="Line Item Total:"
                        invoiceOrderTotalValue={invoiceCopy.orderTotal}
                        productLineItems={invoiceCopy.lineItems}
                        miscLineItems={invoiceCopy.miscellaneousLineItems}
                        kegTransfers={invoiceCopy.kegTransfers}
                        invoiceId={invoice.id}
                        updateInvoiceReconciliationStatus={
                          handleUpdateInvoiceReconciliationStatus
                        }
                        reconciledStatus={reconciledStatus}
                        serverOrderTotal={serverOrderTotal}
                        boxStyle={
                          reconciledStatus ===
                          inventoryObjects.invoiceStatuses.reconciled
                            ? successStyle
                            : defaultStyle
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* Invoice Status Section */}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "56px",
                      width: "24%",
                      border: "1px solid",
                      borderRadius: 1,
                      borderColor: (theme) => theme.palette.terrain[300],
                      backgroundColor: (theme) => theme.palette.terrain[50],
                      padding: 2,
                    }}
                  >
                    <Typography variant="body1">Invoice Status: </Typography>
                    {/* <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "120px",
                        height: "32px",
                        borderRadius: 100,
                        backgroundColor: (theme) =>
                          reconciledStatus ===
                          inventoryObjects.invoiceStatuses.reconciled
                            ? theme.palette.success[600]
                            : theme.palette.error[50],
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) =>
                            reconciledStatus ===
                            inventoryObjects.invoiceStatuses.reconciled
                              ? theme.palette.pure.white
                              : theme.palette.pure.black,
                        }}
                      >
                        {reconciledStatus ===
                        inventoryObjects.invoiceStatuses.reconciled
                          ? "Reconciled"
                          : reconciledStatus ===
                            inventoryObjects.invoiceStatuses.approved
                          ? "Approved"
                          : "Unreconciled"}
                      </Typography>
                    </Box> */}
                    <InvoiceStatusChip status={reconciledStatus} />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "24%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{ width: "100%", height: "40px", maxWidth: "265px" }}
                      onClick={handleGenerateApproval}
                      disabled={
                        reconciledStatus ===
                          inventoryObjects.invoiceStatuses.reconciled &&
                        !generateApprovalPreviewMutation.isLoading
                          ? false
                          : true
                      }
                    >
                      {generateApprovalPreviewMutation.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Typography variant="largeButton">
                          Approve Invoice
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Grid>

        <Grid container sx={{ marginTop: 8 }}>
          <Grid item>
            <Typography variant="h3">Invoice Line Items</Typography>
          </Grid>
          <Box
            sx={{
              marginTop: 6,
              padding: 4,
              marginBottom: 4,
              width: "100%",
              border: "2px solid",
              borderRadius: 4,
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
            }}
          >
            <Box sx={{ marginBottom: 6 }}>
              <InvoiceProductCard
                lineItems={invoiceCopy.lineItems || []}
                updateCache={handleUpdateQueryCache}
                productLibrary={productLibrary}
                vendorId={invoice.vendorIds[0]}
                invoiceId={invoice.id}
                currentLinkedOrders={invoice.orders || []}
                expenseGroups={expenseGroups || []}
                toggleIsExpenseGroupModalOpen={toggleIsExpenseGroupModalOpen}
                openSubgoupEditModal={handleOpenSubgroupEditModal}
                invalidLineItems={incompleteLineItems || {}}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: 6, marginTop: 6 }}>
              <InvoiceKegCard
                kegTransfers={invoiceCopy.kegTransfers || []}
                updateCache={handleUpdateQueryCache}
                invoiceId={invoice.id}
                invalidLineItems={incompleteLineItems || {}}
              />
            </Box>
            <Divider />
            <Box sx={{ marginBottom: 6, marginTop: 6 }}>
              <InvoiceMiscCard
                miscLineItems={invoiceCopy.miscellaneousLineItems || []}
                updateCache={handleUpdateQueryCache}
                invoiceId={invoice.id}
                expenseGroups={expenseGroups || []}
                toggleIsExpenseGroupModalOpen={toggleIsExpenseGroupModalOpen}
                openSubgoupEditModal={handleOpenSubgroupEditModal}
                invalidLineItems={incompleteLineItems || {}}
              />
            </Box>
          </Box>
        </Grid>

        <Grid container direction="column" sx={{ marginTop: 4 }}>
          <Grid item>
            <Typography variant="h3">Additional Information</Typography>
          </Grid>
          <Box
            sx={{
              padding: 4,
              marginTop: 4,
              marginBottom: 4,
              border: "2px solid",
              borderRadius: 4,
              borderColor: (theme) => theme.palette.terrain[300],
              backgroundColor: (theme) => theme.palette.pure.white,
            }}
          >
            <Grid
              container
              direction="column"
              spacing={4}
              sx={{ marginBottom: 4 }}
            >
              <Grid item xs={12} sx={{ marginBottom: 4 }}>
                <TextField
                  size="small"
                  id="additionalInfo"
                  label="Additional Info"
                  placeholder="Delivery driver notes, delivery driver name, etc."
                  multiline
                  fullWidth
                  rows={5}
                  value={additionalInformationText}
                  onChange={handleUpdateAdditionalInformationText}
                  onBlur={handleBlurAdditionalInformation}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </form>
      <ApproveInvoiceModal
        isOpen={approveInvoiceModalOpen}
        toggleModalOpen={toggleApproveInvoiceModalOpen}
        approvalPreview={invoiceApprovalPreview}
        completeInvoiceApproval={handleCompleteApproveInvoice}
        isInvoiceApprovalLoading={approveInvoiceMutation.isLoading}
      />
      <CreateInvoiceModal
        headerText="Edit Invoice"
        buttonText="Save Changes"
        isOpen={editInvoiceModalOpen}
        toggleModalOpen={toggleEditInvoiceModalOpen}
      />
      <CreateExpenseGroupModal
        isOpen={isExpenseGroupModalOpen}
        toggleModalOpen={toggleIsExpenseGroupModalOpen}
        headerText="Edit Groups"
        buttonText="Save List"
        expenseGroups={expenseGroups}
      />
      <CreateExpenseSubgroupModal
        isOpen={isExpenseSubgroupModalOpen}
        toggleModalOpen={toggleIsExpenseSubgroupModalOpen}
        headerText="Edit Subgroups"
        buttonText="Save List"
        expenseGroup={expenseGroupForSubgroupEdit}
      />
    </>
  );
};

export default InvoiceDetailsForm;
