import { Alert, Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuItemCard from "./MenuItemCard";
import AddMenuItemsFooter from "./AddMenuItemsFooter";
import SpecBackButton from "../../../../components/SpecBackButton";
import { cloneDeep } from "lodash";
import SpecSpinner from "../../../../components/SpecSpinner";
import AddMenuItemSeachBar from "./AddMenuItemSeachBar";
import utilFunctions from "../../../../utilities/utilFunctions";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import { useParams } from "react-router-dom";
import { v4 as guidGenerator } from "uuid";

const AddMenuItems = ({ handleBack, menu, productLibrary, recipes, isLoadingLibrary }) => {
  const [itemsToAdd, setItemsToAdd] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [isGeneratingOptions, toggleIsGeneratingOptions] = useState(false);
  const { establishmentid, menuid } = useParams();
  const queryClient = useQueryClient();

  const generateOptionsList = () => {
    let combinedOptions = [...recipes];
    productLibrary?.forEach((x) => {
      x.productFormats?.forEach((f) => {
        let sizeLabel = utilFunctions.extractFormatSizingName(f);

        f.name = x.name + " " + sizeLabel;
        f.isProduct = true;
        combinedOptions.push(f);
      });
    });

    setSearchOptions(combinedOptions);
  };

  useEffect(() => {
    if (!isLoadingLibrary) {
      toggleIsGeneratingOptions(true);
      generateOptionsList();
      toggleIsGeneratingOptions(false);
    }
  }, [menu, isLoadingLibrary]);

  const addBulkMenuItemsMutation = useMutation(
    inventoryClient.addBulkMenuItems,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "menu",
            {
              establishmentId: establishmentid,
              invoiceId: menuid,
            },
          ],
        });
        queryClient.refetchQueries({
          queryKey: [
            "menu",
            {
              establishmentId: establishmentid,
              menuId: menuid,
            },
          ],
        });

        handleBack();
      },
    }
  );

  const handleItemSelect = (item) => {
    const clonedItem = cloneDeep(item);
    clonedItem.uuidForList = guidGenerator();
    setItemsToAdd([clonedItem, ...itemsToAdd]);
  };

  const handleRemoveItem = (itemListUuid) => {
    setItemsToAdd(itemsToAdd.filter((x) => x.uuidForList !== itemListUuid));
  };

  if (isLoadingLibrary || isGeneratingOptions) {
    return <SpecSpinner open text="Prepping..." />;
  }

  if (addBulkMenuItemsMutation.isLoading) {
    return <SpecSpinner open text="Adding Items..." />;
  }

  const handleAddItems = async () => {
    const payload = [];

    itemsToAdd.forEach((x) => {
      if (x.isProduct) {
        payload.push({ menuId: menuid, productFormatId: x.id });
      } else {
        payload.push({ menuId: menuid, recipeId: x.id });
      }
    });

    await addBulkMenuItemsMutation.mutateAsync({
      establishmentId: establishmentid,
      menuItemCollection: payload,
    });
  };

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          display: "flex",
          maxWidth: "2440px",
          padding: 4,
          paddingBottom: "80px",
          marginBottom: "120px",
          zIndex: 0,
        }}
      >
        <Grid item>
          <SpecBackButton onClick={handleBack} backText={menu?.name} />
        </Grid>
        <Grid item>
          <Box sx={{ marginTop: 6 }}>
            <Typography variant="h2">Add Menu Items</Typography>
          </Box>
          <Box sx={{ marginTop: 6 }}>
            <Grid item>
              <Typography variant="body1">
                The options here are populated from your Product Library and Recipes. Enter your menu item name into the input field below to begin:
              </Typography>
              {searchOptions?.length === 0 ? <Alert sx={{marginTop: 4}} severity="warning"><Typography variant="body1">Your Product Library and Recipe Library are empty. Please add Products and Recipes there first, and then those items will appear here and can be added to a Menu.</Typography></Alert> : null}
              <Grid item sx={{ marginTop: 4 }}>
                <AddMenuItemSeachBar
                  options={searchOptions}
                  onSelect={handleItemSelect}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ marginTop: 6 }}>
            <Grid item sx={{ marginBottom: 6 }}>
              <Typography variant="h3">Items Added</Typography>
            </Grid>
            {itemsToAdd.length > 0 ? (
              <Grid item>
                {itemsToAdd.map((x) => (
                  <MenuItemCard
                    item={x}
                    key={x.uuidForList}
                    removeItem={handleRemoveItem}
                  />
                ))}
              </Grid>
            ) : (
              <Grid item>
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                  You have not added any items yet...
                </Typography>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <AddMenuItemsFooter
        handleBack={handleBack}
        itemCount={itemsToAdd.length}
        submit={handleAddItems}
      />
    </>
  );
};

export default AddMenuItems;
