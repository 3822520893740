import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import CostProductListRow from "./CostProductListRow";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  lineHeight: 1.2,
};

const CostProductListTable = () => {
  const stockCountDummyData = [
    {
      productName: "Teremana",
      sizingVariation: "750 mL",
      unitCost: "$38.00",
      servingSize: "750 mL",
      costPoz: "$1.20",
      costPserving: "$1.80",
      salePrice: "$9.00",
      unitSaleSize: "1.25 fl oz",
      markUp: "500%",
      profitPerUnit: "$7.80",
    },
    {
      productName: "Buffalo Trace",
      sizingVariation: "1 liter",
      unitCost: "$44.00",
      servingSize: "1.25 fl oz",
      costPoz: "$1.10",
      costPserving: "$1.40",
      salePrice: "$10.00",
      unitSaleSize: "1.25 fl oz",
      markUp: "500%",
      profitPerUnit: "$8.80",
    },
    {
      productName: "Aviation Gin",
      sizingVariation: "1 liter",
      unitCost: "$44.00",
      servingSize: "1.25 fl oz",
      costPoz: "$1.10",
      costPserving: "$1.40",
      salePrice: "$12.00",
      unitSaleSize: "1.25 fl oz",
      markUp: "500%",
      profitPerUnit: "$9.80",
    },
    {
      productName: "Sauza",
      sizingVariation: "750 mL",
      unitCost: "$28.00",
      servingSize: "1.25 fl oz",
      costPoz: "$1.20",
      costPserving: "$1.40",
      salePrice: "$8.00",
      unitSaleSize: "1.25 fl oz",
      markUp: "500%",
      profitPerUnit: "$6.80",
    },
  ];

  return (
    <TableContainer
      sx={{
        overflow: "scroll",
        borderRadius: 2,
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain[400],
      }}
    >
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableHead sx={{ height: "24px" }}>
          <TableRow sx={{ height: 24 }}>
            <TableCell sx={[tableHeadStyle, { width: "360px" }]}>
              Product Name
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "118px", textAlign: "center" }]}
            >
              Unit Cost
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "118px", textAlign: "center" }]}
            >
              Unit Size
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "118px", textAlign: "center" }]}
            >
              Cost p/fl.oz
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "118px", textAlign: "center" }]}
            >
              Cost p/unit
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "118px", textAlign: "center" }]}
            >
              Sale Price p/oz.
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "100px", textAlign: "center" }]}
            >
              Unit Sale Size
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "100px", textAlign: "center" }]}
            >
              Mark-up (%)
            </TableCell>
            <TableCell
              sx={[tableHeadStyle, { width: "130px", textAlign: "center" }]}
            >
              Profit p/unit
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stockCountDummyData.map((x) => (
            <CostProductListRow key={x.productName} data={x} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CostProductListTable;
