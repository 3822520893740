import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

const tableCellStyle = {
  height: "48px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  borderBottom: "none",
  borderTop: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
  fontWeight: 400,
};

const sizeBadge = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "20px",
  width: "fit-content",
  backgroundColor: (theme) => theme.palette.info[600],
  color: (theme) => theme.palette.pure.white,
  fontWeight: 700,
  borderRadius: 1,
  padding: 2,
};

const CountProductListRow = ({ data }) => {
  return (
    <TableRow sx={{ height: 24 }}>
      <TableCell sx={[tableCellStyle, { width: "360px" }]}>
        <Box>
          <Typography variant="body1">{data.productName}</Typography>
        </Box>
        <Box>
          <Typography variant="overline">{data.sizingVariation}</Typography>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.unitCost}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={sizeBadge}>{data.servingSize}</Box>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {data.costPoz}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {data.costPserving}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {data.salePrice}
        </Box>
      </TableCell>
      <TableCell
        sx={[
          tableCellStyle,
          {
            width: "118px",
          },
        ]}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={sizeBadge}>{data.unitSaleSize}</Box>
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {data.markUp}
        </Box>
      </TableCell>
      <TableCell sx={[tableCellStyle, { width: "118px" }]}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {data.profitPerUnit}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default CountProductListRow;
