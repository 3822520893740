import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Menu,
  Grid,
  MenuItem,
  Pagination,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import CTABlock from "../../components/CTABlock";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateRecipeModal from "../../components/Modals/CreateRecipeModal";
import RecipeList from "./components/RecipeList";
import SpecSpinner from "../../components/SpecSpinner";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../clients/inventoryClient";
import { useNavigate, useParams } from "react-router-dom";
import useEstablishmentTags from "../../hooks/useEstablishmentTags";
import Store, { activeRouteKeys } from "../../Store/Store";
import useRecipesPaginated from "../../hooks/useRecipesPaginated";
import FilterListIcon from "@mui/icons-material/FilterList";
import useRecipePaginatedSearch from "../../hooks/useRecipePaginatedSearch";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { cloneDeep, debounce } from "lodash";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import useEstablishmentRateLimits from "../../hooks/useEstablishmentRateLimits";
import inventoryObjects from "../../clients/inventoryObjects";
import useEstablishmentRecipes from "../../hooks/useEstablishmentRecipes";
import TagPill from "../../components/TagPill";
import TagsModal from "../../components/Modals/TagsModal";

const Recipes = () => {
  const { establishmentid } = useParams();
  const queryClient = useQueryClient();
  const [creatingRecipeModalOpen, toggleCreatingRecipeModalOpen] =
    useState(false);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [localSearchText, setLocalSearchText] = useState("");
  const store = useContext(Store);
  const [tagsModalOpen, toggleTagsModalOpen] = useState(false);
  const [activeTagFilters, setActiveTagFilters] = useState([]);
  const disableBaseData = store?.recipeSearchText ? true : false;
  const { data: paginatedRecipeData, isLoading: isLoadingPaginatedRecipes } =
    useRecipesPaginated(
      establishmentid,
      store?.recipeSelectedPage,
      store?.recipeFilters,
      disableBaseData
    );
  const { data: paginatedSearchData, isLoading: isLoadingPaginatedSearchData } =
    useRecipePaginatedSearch(
      establishmentid,
      store?.recipeSearchText,
      store?.recipeSelectedPage,
      store?.recipeFilters
    );
  const { isLoading: isLoadingTags, data: recipeTagData } =
    useEstablishmentTags(establishmentid);
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useEstablishmentSubscription(establishmentid);
  const { data: rateLimitData, isLoading: isLoadingRateLimits } =
    useEstablishmentRateLimits(
      establishmentid,
      subscriptionData?.isActive === false ? true : false
    );

  // we want to remove this when everything moves to using the paginated view. The reason its here is to ensure the data is loaded when we update
  // its cache as a result of adding or removing recipes.
  const { isLoading: isLoadingFullLibrary } =
    useEstablishmentRecipes(establishmentid);
  const navigate = useNavigate();
  const recipeRef = useRef();
  useEffect(() => {
    setLocalSearchText(store?.recipeSearchText);
    store.updateActiveRoute(
      activeRouteKeys.recipes,
      `spec/${establishmentid}/recipes`
    );

    if (localStorage.getItem("showInactiveRecipes") !== null) {
      let sortObj = cloneDeep(store.recipeFilters);
      sortObj.showInactive = localStorage.getItem("showInactiveRecipes");
      store.setRecipeFilters(sortObj);
    }
  }, []);

  const addRecipeMutation = useMutation(inventoryClient.addRecipe, {
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [
          "recipes-paginated",
          {
            establishmentId: establishmentid,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "recipes-paginated",
          {
            establishmentId: establishmentid,
          },
        ],
        type: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [
          "rate-limits",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
        type: "all",
      });
      queryClient.refetchQueries({
        queryKey: [
          "rate-limits",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
        type: "all",
      });

      queryClient.setQueryData(
        ["recipes", { establishmentId: establishmentid }],
        (prev) => {
          const newRecipes = [...prev.recipes, result.recipe];

          return { recipes: newRecipes };
        }
      );
      navigate(result?.recipe?.id);
    },
  });

  const handleCreateTagCallback = () => {
    queryClient.invalidateQueries({
      queryKey: ["recipe-tags", { establishmentId: establishmentid }],
    });
    queryClient.refetchQueries({
      queryKey: ["recipe-tags", { establishmentId: establishmentid }],
    });
  };

  const handlePageSet = (_, page) => {
    store.setRecipeSelectedPage(page);
    recipeRef?.current?.scrollIntoView();
  };

  const handleSortAZ = () => {
    let sortObj = cloneDeep(store.recipeFilters);
    sortObj.sortAsc = true;
    sortObj.sortByCreatedDate = false;
    store.setRecipeFilters(sortObj);
    store.setRecipeSelectedPage(1);
    handleCloseSortMenu();
  };

  const handleSortZA = () => {
    let sortObj = cloneDeep(store.recipeFilters);
    sortObj.sortAsc = false;
    sortObj.sortByCreatedDate = false;
    store.setRecipeFilters(sortObj);
    store.setRecipeSelectedPage(1);
    handleCloseSortMenu();
  };

  const handleSortOldestNewest = () => {
    let sortObj = cloneDeep(store.recipeFilters);
    sortObj.sortAsc = false;
    sortObj.sortByCreatedDate = true;
    store.setRecipeFilters(sortObj);
    store.setRecipeSelectedPage(1);
    handleCloseSortMenu();
  };

  const handleSortNewestOldest = () => {
    let sortObj = cloneDeep(store.recipeFilters);
    sortObj.sortAsc = true;
    sortObj.sortByCreatedDate = true;
    store.setRecipeFilters(sortObj);
    store.setRecipeSelectedPage(1);
    handleCloseSortMenu();
  };

  const handleCloseSortMenu = () => {
    setSortMenuAnchor(null);
  };

  const handleOpenSortMenu = (e) => {
    setSortMenuAnchor(e.currentTarget);
  };

  const handleToggleShowInactive = () => {
    let sortObj = cloneDeep(store.recipeFilters);
    sortObj.showInactive = !sortObj.showInactive;
    store.setRecipeFilters(sortObj);
    store.setRecipeSelectedPage(1);
    localStorage.setItem("showInactiveRecipes", sortObj.showInactive);
  };

  const handleSearch = (e) => {
    if (!e.target.value) {
      queryClient.invalidateQueries({
        queryKey: ["recipes-paginated"],
      });
      queryClient.refetchQueries({
        queryKey: ["recipes-paginated"],
        type: "all",
      });
    }
    store.setRecipeSearchText(e.target.value);
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleSearchChange = (e) => {
    setLocalSearchText(e.target.value);
    debouncedSearchHandler.cancel();
    store.setRecipeSelectedPage(1);
    debouncedSearchHandler(e);
  };

  const handleAddRecipe = (payload) => {
    payload.establishmentId = establishmentid;
    addRecipeMutation.mutate(payload);
  };

  const toggleTagsModal = () => {
    toggleTagsModalOpen(!tagsModalOpen);
  };

  const handleTagClick = (tag) => {
    let sortObj = cloneDeep(store.recipeFilters);
    if (sortObj.tagFilters.find((x) => x === tag.id)) {
      sortObj.tagFilters = sortObj.tagFilters.filter((x) => x !== tag.id);
      store.setRecipeFilters(sortObj);
      store.setRecipeSelectedPage(1);
    } else {
      sortObj.tagFilters.push(tag.id);
      store.setRecipeFilters(sortObj);
      store.setRecipeSelectedPage(1);
    }
  };

  const handleSaveTagFilterClick = (tagFilterObj) => {
    let sortObj = cloneDeep(store.recipeFilters);
      sortObj.tagFilters = tagFilterObj;
      store.setRecipeFilters(sortObj);
      store.setRecipeSelectedPage(1);
  };

  useEffect(() => {
    const activeTags = recipeTagData?.tags?.filter(tag => store.recipeFilters.tagFilters.includes(tag.id));
    setActiveTagFilters(activeTags);

  },[store.recipeFilters.tagFilters, recipeTagData])

  if (
    (isLoadingPaginatedRecipes && recipeTagData?.recipes === undefined) ||
    (isLoadingTags && recipeTagData?.tags === undefined) ||
    isLoadingSubscription ||
    isLoadingRateLimits ||
    isLoadingFullLibrary
  ) {
    return <SpecSpinner open text={"Loading Recipes..."} />;
  }

  const recipeRateLimits = rateLimitData?.sectionLimits?.find(
    (x) => x.type === inventoryObjects.rateLimitType.recipes
  );
  const isRateLimited = subscriptionData?.isActive
    ? false
    : rateLimitData?.ignoreLimits || recipeRateLimits?.isUnderLimit
    ? false
    : true;
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {subscriptionData?.isActive === false ? (
          <Grid item sx={{ marginBottom: 2 }}>
            <Alert severity="warning">
              You are on a Spec Free plan. The Spec free plan includes up to 30
              free recipes! Upgrade to Spec Pro for unlimited recipes, dynamic
              cost analysis and more!
            </Alert>{" "}
          </Grid>
        ) : null}
        <Box sx={{ marginBottom: 6 }}>
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={9}>
              <Typography variant="h2">All Recipes</Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                disabled={isRateLimited}
                onClick={() => toggleCreatingRecipeModalOpen(true)}
              >
                <AddOutlinedIcon />
                <Typography variant="largeButton">Create New Recipe</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Grid container direction="row">
          <Grid item xs={12}>
            {/* SEARCH BAR */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="off"
                  id="recipeSearch"
                  onChange={handleSearchChange}
                  value={localSearchText}
                  inputProps={{
                    sx: {
                      marginBottom: 1,
                    },
                  }}
                  sx={{
                    backgroundColor: (theme) => theme.palette.pure.white,
                    borderColor: (theme) => theme.palette.terrain[400],
                    width: "340px",
                    borderRadius: 1,
                  }}
                  label={
                    <>
                      <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                      Search for recipes...
                    </>
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 8,
                  marginLeft: "auto",
                  padding: 0.75,
                }}
              >
                <Switch
                  checked={store?.recipeFilters.showInactive}
                  onChange={handleToggleShowInactive}
                  size="small"
                />
                <Typography
                  variant="body1"
                  sx={{ fontSize: 12, marginLeft: 1 }}
                >
                  Show Inactive
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{ marginRight: 1, marginTop: 8, marginLeft: 2 }}
                onClick={() => toggleTagsModalOpen(true)}
              >
                <Typography variant="largeButton">Tags</Typography>
              </Button>
              <Button
                variant="outlined"
                sx={{ marginRight: 1, marginTop: 8, marginLeft: 2 }}
                onClick={handleOpenSortMenu}
              >
                <FilterListIcon sx={{ fontSize: 18, marginRight: 2 }} />
                <Typography variant="largeButton">Sort</Typography>
              </Button>
              <Menu
                anchorEl={sortMenuAnchor}
                open={!!sortMenuAnchor}
                onClose={handleCloseSortMenu}
              >
                <MenuItem
                  sx={{ margin: "5px" }}
                  selected={
                    store?.recipeFilters.sortAsc &&
                    !store?.recipeFilters.sortByCreatedDate
                  }
                  id="name,asc"
                  onClick={handleSortAZ}
                >
                  Name A-Z
                </MenuItem>
                <MenuItem
                  sx={{ margin: "5px" }}
                  id="name,desc"
                  selected={
                    !store?.recipeFilters.sortAsc &&
                    !store?.recipeFilters.sortByCreatedDate
                  }
                  onClick={handleSortZA}
                >
                  Name Z-A
                </MenuItem>
                <MenuItem
                  sx={{ margin: "5px" }}
                  id="createdDate,desc"
                  selected={
                    store?.recipeFilters.sortAsc &&
                    store?.recipeFilters.sortByCreatedDate
                  }
                  onClick={handleSortNewestOldest}
                >
                  Newest First
                </MenuItem>
                <MenuItem
                  sx={{ margin: "5px" }}
                  id="createdDate,asc"
                  selected={
                    !store?.recipeFilters.sortAsc &&
                    store?.recipeFilters.sortByCreatedDate
                  }
                  onClick={handleSortOldestNewest}
                >
                  Oldest First
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 4 }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                width: { xs: "300px", sm: "100%" },
                maxWidth: "100%",
                flexWrap: "wrap",
              }}
            >
              <Grid
                direction="row"
                container
                flexWrap={2}
                sx={{ maxWidth: "100%" }}
              >
                {activeTagFilters?.map((x) => (
                  <TagPill
                    key={x.id}
                    onClickCallback={handleTagClick}
                    selected={true}
                    tag={x}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {isLoadingPaginatedSearchData ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : store?.recipeSearchText ? (
          <>
            <RecipeList
              recipes={paginatedSearchData?.recipes}
              recipeTags={recipeTagData.tags}
              isLoadingPaginatedSearchData={isLoadingPaginatedSearchData}
            />
          </>
        ) : !store?.recipeSearchText &&
          (paginatedRecipeData?.recipes.length > 0 ||
            store?.recipeFilters?.tagFilters?.length > 0) ? (
          <RecipeList
            recipes={paginatedRecipeData?.recipes}
            recipeTags={recipeTagData.tags}
            isLoadingPaginatedRecipes={isLoadingPaginatedRecipes}
          />
        ) : (
          <CTABlock
            header="Your Recipe List is Empty..."
            subheader="You have not created any recipes yet. Get started now..."
            ButtonImage={AddOutlinedIcon}
            buttonText="Create New Recipe"
            handleCTA={() => toggleCreatingRecipeModalOpen(true)}
          />
        )}
        <CreateRecipeModal
          isOpen={creatingRecipeModalOpen}
          toggleModalOpen={toggleCreatingRecipeModalOpen}
          headerText="Create New Recipe"
          buttonText="Create Recipe"
          loading={addRecipeMutation.isLoading}
          addRecipe={handleAddRecipe}
        />
        <TagsModal
          isOpen={tagsModalOpen}
          toggleModalOpen={toggleTagsModal}
          recipeTags={recipeTagData}
          isLoadingTags={isLoadingTags}
          tagFilters={store.recipeFilters.tagFilters}
          saveTagFilters={handleSaveTagFilterClick}
          handleCreateTagCallback={handleCreateTagCallback}
        />
        <Pagination
          sx={{ marginTop: 6 }}
          count={
            store?.recipeSearchText
              ? paginatedSearchData?.totalPages
              : paginatedRecipeData?.totalPages
          }
          onChange={handlePageSet}
          page={store?.recipeSelectedPage}
        />
      </Box>
    </>
  );
};

export default Recipes;
