import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import DistributorCard from "./components/DistributorCard";
import EditDistributorModal from "../../components/Modals/EditDistributorModal";
import AddDistributor from "./components/AddDistributor";
import inventoryClient from "../../clients/inventoryClient";
import Store from "../../Store/Store";
import CTABlock from "../../components/CTABlock";
import DeleteDistributorModal from "../../components/Modals/DeleteDistributorModal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SpecSpinner from "../../components/SpecSpinner";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const Vendors = () => {
  const [error, toggleError] = useState(false);
  const { establishmentid } = useParams();
  const [editDistributorModalOpen, toggleEditDistributorModalOpen] =
    useState(false);
  const [addingDistributor, toggleAddingDistributor] = useState(false);
  const [loading, toggleLoading] = useState(true);
  const [distributors, setDistributors] = useState([]);
  const [distributorToEdit, setDistributorToEdit] = useState(undefined);
  const [deleteDistributorModalOpen, toggleDeleteDistributorModalOpen] =
    useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [spinnerOpen, setSpinnerOpen] = useState(false);
  const [spinnerText, setSpinnerText] = useState("");
  const queryClient = useQueryClient();
  const [selectedDistributorId, setSelectedDistributorId] = useState(null);
  const [callback, setCallback] = useState(false);

  const store = useContext(Store);

  const loadDistributors = async () => {
    toggleError(false);
    toggleLoading(true);
    setSpinnerText("Loading Distributors...");
    setSpinnerOpen(true);

    const data = await inventoryClient.getDistributors(
      store.currentEstablishment?.id
    );

    if (data.error) {
      toggleError(true);
      toggleLoading(false);
      setSpinnerOpen(false);
      setSpinnerText("");
      return;
    }

    setDistributors(data.data.vendors);
    toggleLoading(false);
    setSpinnerOpen(false);
  };

  useEffect(() => {
    loadDistributors();
  }, [callback]);

  const handleDeleteModal = () => {
    toggleDeleteDistributorModalOpen(!deleteDistributorModalOpen);
  };

  const deleteCallback = () => {
    setCallback(!callback);
  };

  const handleSelectDistributor = (id) => {
    setSelectedDistributorId(id);
  };

  const handleOpenEditModal = (distributor) => {
    setDistributorToEdit(distributor);
    toggleEditDistributorModalOpen(true);
  };

  const handleCloseEditDistributorModal = () => {
    setDistributorToEdit(undefined);
    toggleEditDistributorModalOpen(false);
  };

  const distributorUpdateCallback = (result) => {
    if (result.error) {
      setErrorMessage(
        "A problem occured while updating your distributor. Please refresh and try again."
      );
    } else {
      console.log("hit");
      const updatedDistributor = result.data.vendor;
      const newDistributors = distributors.map((d) => {
        if (d.id === updatedDistributor.id) {
          return updatedDistributor;
        }
        return d;
      });
      setDistributors(newDistributors);
      setAlertSuccess("Success! Distributor updated.");
    }
    toggleEditDistributorModalOpen(false);
  };

  const handleAddDistributors = async (distributors) => {
    toggleLoading(true);
    setSpinnerOpen(true);
    setSpinnerText("Adding Distributor...");
    await inventoryClient.addDistributors(
      distributors,
      store.currentEstablishment?.id
    );
    queryClient.invalidateQueries({
      queryKey: [
        "vendors",
        {
          establishmentId: establishmentid,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "vendors",
        {
          establishmentId: establishmentid,
        },
      ],
    });
    await loadDistributors();
    toggleAddingDistributor(false);
    toggleLoading(false);
    setSpinnerOpen(false);
    setSpinnerText("");
  };

  if (loading) {
    return <SpecSpinner open={spinnerOpen} text={spinnerText} />;
  }

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {error && (
          <Typography variant="h6" sx={{ color: "red" }}>
            There was an error fetching vendor data, please refresh
          </Typography>
        )}
        {addingDistributor ? (
          <AddDistributor
            toggleAddingDistributor={toggleAddingDistributor}
            addDistributors={handleAddDistributors}
            toggleDeleteDistributorModalOpen={handleDeleteModal}
          />
        ) : (
          <>
            <Grid
              container
              sx={{
                maxWidth: "2440px",
                marginBottom: 6,
              }}
            >
              <Grid item xs={9}>
                <Typography variant="h2">Vendors</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    padding: 4,
                    justifySelf: "flex-end",
                    height: "40px",
                  }}
                  onClick={() => toggleAddingDistributor(true)}
                >
                  <AddOutlinedIcon sx={{ marginRight: 1, fontSize: 20 }} />
                  <Typography variant="smallButton">Add Vendor</Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid container sx={{ marginBottom: 2, width: "100%" }}>
              <Grid item xs={12}>
                <Typography variant="h3" sx={{ marginBottom: 6 }}>
                  Your Vendors
                </Typography>
                {distributors && distributors.length > 0 ? (
                  distributors.map((d) => (
                    <DistributorCard
                      key={d.id}
                      distributor={d}
                      selectDistributorId={handleSelectDistributor}
                      toggleEditDistributorModalOpen={handleOpenEditModal}
                      toggleDeleteDistributorModalOpen={handleDeleteModal}
                      deleteCallback={deleteCallback}
                    />
                  ))
                ) : (
                  <CTABlock
                    header={"You haven't added any distributors"}
                    subheader={
                      "Add distributors for sending orders and building out your inventory"
                    }
                    hideButton
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <EditDistributorModal
        distributor={distributorToEdit}
        toggleEditDistributorModal={handleCloseEditDistributorModal}
        open={editDistributorModalOpen}
        distributorUpdateCallback={distributorUpdateCallback}
      />
      <DeleteDistributorModal
        deleteCallback={deleteCallback}
        isOpen={deleteDistributorModalOpen}
        toggleModalOpen={handleDeleteModal}
        distributorId={selectedDistributorId}
      />
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default Vendors;
