import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MenuItemRow from "./MenuItemRow";
import ServingSizeModal from "../../../components/Modals/ServingSizeModal";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import DescriptionToolTip from "../../../components/DescriptionToolTip";
import utilFunctions from "../../../utilities/utilFunctions";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  fontWeight: 600,
  border: "1px solid",
  borderBottom: "none",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  fontSize: "10px",
  lineHeight: 1.2,
};

const MenuListTable = ({
  toggleServingSizeModalOpen,
  menuItems,
  establishmentUoMs,
  subscriptionState,
  reverseAzOrder,
}) => {
  const [isServingSizeModalOpen, toggleServingSizeModal] = useState(false);
  const [itemForServingSize, setItemForServingSize] = useState(false);
  const [menuItemsCopy, setMenuItemsCopy] = useState([]);
  const queryClient = useQueryClient();
  let { menuid, establishmentid } = useParams();

  useEffect(() => {
    if (reverseAzOrder) {
      setMenuItemsCopy([
        ...utilFunctions.sortByNameReverseAlphabetically(menuItems),
      ]);
    } else {
      setMenuItemsCopy([...utilFunctions.sortByNameAlphabetically(menuItems)]);
    }
  }, [menuItems, reverseAzOrder]);

  const menuItemMutation = useMutation(inventoryClient.patchMenuItem, {
    onSuccess: (result) => {
      const menuItemsUpdated = menuItemsCopy.map((x) => {
        if (x.id === result.menuItem.id) {
          return result.menuItem;
        } else {
          return x;
        }
      });

      const existingMenu = queryClient.getQueryData([
        "menu",
        { establishmentId: establishmentid, menuId: menuid },
      ]).menu;
      queryClient.setQueryData(
        ["menu", { establishmentId: establishmentid, menuId: menuid }],
        {
          menu: Object.assign({}, existingMenu, {
            menuItems: menuItemsUpdated,
          }),
        }
      );

      setMenuItemsCopy(menuItemsUpdated);
    },
  });

  const handleUpdateMenuItem = async (property, newValue, menuItemId) => {
    let payload = {
      establishmentId: establishmentid,
      menuItemId,
      menuId: menuid,
    };

    payload[`${property}Update`] = { value: newValue };
    await menuItemMutation.mutateAsync(payload);
  };

  const handleOpenServingSize = (menuItem) => {
    setItemForServingSize(menuItem);
    toggleServingSizeModal(true);
  };

  const handleCloseServingSize = () => {
    setItemForServingSize(null);
    toggleServingSizeModal(false);
  };

  return (
    <>
      <TableContainer
        sx={{
          overflow: "scroll",
          borderRadius: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <Table
          sx={{ width: "100%", overflow: "scroll" }}
          aria-label="customized table"
        >
          <TableHead sx={{ height: "24px" }}>
            <TableRow sx={{ height: 24 }}>
              <TableCell
                sx={[tableHeadStyle, { width: "200px", borderLeft: "none" }]}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Item Name
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Item Name"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            Name of item. This can be any product in the Product
                            Library, including Wine, Beer, or even dry goods.
                            Can also be Recipes out of the Recipe Library.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Serving Size
                  <Box component="div" sx={{ marginRight: -2 }}>
                    <DescriptionToolTip
                      header="Serving Size"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The quantity of the Product or Recipe that is sold.
                            For example, a can of beer would be 1 and a glass of
                            wine would be 5 fluid ounces.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "110px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Ingredient Cost
                  <Box component="div" sx={{ marginRight: -3.5 }}>
                    <DescriptionToolTip
                      header="Ingredient Cost"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The cost of the product, or the ingredient costs of
                            the recipe. T his will update depending on the
                            serving size.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={[tableHeadStyle, { borderRight: "none", width: "100px" }]}
              >
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Labor Cost
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Labor Cost"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The labor cost of a recipe. This will update
                            depending on the serving size. This field is
                            disabled for products.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Total Cost
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Total Cost"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The sum of the ingredient cost and the labor cost
                            (if applicable).
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Markup
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Markup"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The amount you wish to multiply the ingredient cost
                            of the item in order to calculate a recommended menu
                            price.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Rec Menu Price
                  <Box component="div" sx={{ marginRight: -3.5 }}>
                    <DescriptionToolTip
                      header="Recommended Menu Price"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The price the item should be sold at based on
                            desired markup. Calculated by multiplying markup by
                            the ingredient cost.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Menu Price
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Menu Price"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The price the item is sold for on your actual menu.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Target Spread
                  <Box component="div" sx={{ marginRight: -3.5 }}>
                    <DescriptionToolTip
                      header="Target Spread"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The difference between recommended menu price and
                            actual menu price. A positive number means you are
                            charging more than your recommended menu price, and
                            a negative number means you are charging less.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Ingredient %
                  <Box component="div" sx={{ marginRight: -3.5 }}>
                    <DescriptionToolTip
                      header="Ingredient %"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The percentage of the menu price that is paying for
                            the ingredient cost. Also known as Pour Cost.
                            Calculated by dividing ingredient cost by the menu
                            price.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Labor %
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Labor %"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The percentage of the menu price that is paying for
                            the labor cost. Calculated by dividing labor cost by
                            the menu price.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Total Cost %
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Labor %"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The total percentage of the menu price that is
                            paying for the labor cost and ingredient cost.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Net Profit
                  <Box component="div" sx={{ marginRight: -3 }}>
                    <DescriptionToolTip
                      header="Net Profit per Sale"
                      description={
                        <Box component="div">
                          <Box sx={{ marginTop: 1 }}>
                            The net amount of profit every time the item is
                            sold. Calculated by subtracting the total cost from
                            the menu price.
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                sx={[tableHeadStyle, { width: "50px", borderRight: "none" }]}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuItemsCopy?.map((x) => (
              <MenuItemRow
                key={x.id}
                item={x}
                toggleServingSizeModalOpen={toggleServingSizeModalOpen}
                openServingSize={handleOpenServingSize}
                updateMenuItem={handleUpdateMenuItem}
                subscriptionState={subscriptionState}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ServingSizeModal
        isOpen={isServingSizeModalOpen}
        toggleModalOpen={handleCloseServingSize}
        loading={menuItemMutation.isLoading}
        menuItem={itemForServingSize}
        unitsOfMeasurement={establishmentUoMs}
        updateMenuItem={handleUpdateMenuItem}
      />
    </>
  );
};

export default MenuListTable;
