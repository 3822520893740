import React, { useEffect, useState } from "react";

import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import utilFunctions from "../../../../../utilities/utilFunctions";

const orderDayLongMap = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
};

const DeliveryDaySection = ({ orderDay, updateOrderDay, modalView }) => {
  const [selectedOption, setSelectedOption] = useState(0);

  const DeliveryDaySectionStyle = modalView
    ? {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }
    : {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      };

  useEffect(() => {
    if (orderDay.sameDay) {
      setSelectedOption(1);
      return;
    }

    if (
      parseInt(orderDay.expectedDeliveryDay) !=
      utilFunctions.getNextDayInt(orderDay.day)
    ) {
      setSelectedOption(2);
      return;
    }
  }, []);

  const setNextDay = () => {
    setSelectedOption(0);
    const copy = Object.assign({}, orderDay);
    copy.expectedDeliveryDay = utilFunctions.getNextDayInt(copy.day);
    copy.sameDay = false;
    updateOrderDay(copy);
  };

  const setSameDay = () => {
    setSelectedOption(1);
    const copy = Object.assign({}, orderDay);
    copy.expectedDeliveryDay = copy.day;
    copy.sameDay = true;
    updateOrderDay(copy);
  };

  const setDayChoice = (e) => {
    const copy = Object.assign({}, orderDay);
    copy.expectedDeliveryDay = e.target.value;
    copy.sameDay = false;
    updateOrderDay(copy);
  };
  return (
    <Box>
      <Grid container sx={DeliveryDaySectionStyle}>
        <Grid item xs={modalView && 12} md={modalView ? 12 : 4}>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 4, marginTop: 4 }}
          >
            Orders Placed on <b>{orderDayLongMap[orderDay.day]}</b> will arrive
            on the:
          </Typography>
        </Grid>
        <Grid item xs={modalView ? 12 : 9} md={modalView ? 12 : 8}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid item xs={4} md={3} lg={2} sx={{ marginRight: 4 }}>
              <Button
                variant="outlined"
                sx={{
                  height: 40,
                  width: 118,
                  backgroundColor: (theme) =>
                    selectedOption === 0
                      ? theme.palette.secondary[800]
                      : theme.palette.pure.white,

                  border: "2px solid #E7E7E7",
                  padding: 2,
                  color: (theme) =>
                    selectedOption === 0
                      ? theme.palette.pure.white
                      : theme.palette.secondary[800],
                }}
                onClick={setNextDay}
              >
                <Typography>Next Day</Typography>
              </Button>
            </Grid>
            <Grid
              item
              sx={{ marginRight: 4, marginLeft: 4 }}
              xs={4}
              md={3}
              lg={2}
            >
              <Button
                variant="outlined"
                sx={{
                  height: 40,
                  width: 118,
                  backgroundColor: (theme) =>
                    selectedOption === 1
                      ? theme.palette.secondary[800]
                      : theme.palette.pure.white,

                  border: "2px solid #E7E7E7",
                  color: (theme) =>
                    selectedOption === 1
                      ? theme.palette.pure.white
                      : theme.palette.secondary[800],
                  padding: 2,
                }}
                onClick={setSameDay}
              >
                <Typography>Same Day</Typography>
              </Button>
            </Grid>
            <Grid
              item
              sx={{ marginRight: 4, marginLeft: 4 }}
              xs={4}
              md={3}
              lg={2}
            >
              <Button
                variant="outlined"
                sx={{
                  height: 40,
                  width: 118,
                  backgroundColor: (theme) =>
                    selectedOption === 2
                      ? theme.palette.secondary[800]
                      : theme.palette.pure.white,

                  border: "2px solid #E7E7E7",
                  color: (theme) =>
                    selectedOption === 2
                      ? theme.palette.pure.white
                      : theme.palette.secondary[800],
                  padding: 2,
                }}
                onClick={() => setSelectedOption(2)}
              >
                <Typography>Specify Day</Typography>
              </Button>
            </Grid>
            <Grid item sx={{ marginRight: 4, marginLeft: 4 }} xs={2} md={1}>
              {selectedOption === 2 ? (
                <Select
                  variant="outlined"
                  label={null}
                  sx={{ height: 40 }}
                  value={orderDay.expectedDeliveryDay}
                  onChange={setDayChoice}
                >
                  <MenuItem value={0}>Monday</MenuItem>
                  <MenuItem value={1}>Tuesday</MenuItem>
                  <MenuItem value={2}>Wednesday</MenuItem>
                  <MenuItem value={3}>Thursday</MenuItem>
                  <MenuItem value={4}>Friday</MenuItem>
                  <MenuItem value={5}>Saturday</MenuItem>
                  <MenuItem value={6}>Sunday</MenuItem>
                </Select>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeliveryDaySection;
