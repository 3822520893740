import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { startCase } from "lodash";
import { useNavigate } from "react-router-dom";
import Store from "../../../Store/Store";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const EstablishmentCard = ({ establishment, isFreeAccount }) => {
  const navigate = useNavigate();
  const store = useContext(Store);

  const handleNavigateToEstablishment = () => {
    navigate(`/spec/${store.currentEstablishment?.id}/settings`);
  };
  return (
    <Box
      onClick={handleNavigateToEstablishment}
      sx={{ display: "flex", flexDirection: "row", width: "100%" }}
    >
      <Box
        sx={{
          width: "100%",
          height: "180px",
          backgroundColor: (theme) => theme.palette.pure.white,
          borderRadius: 2,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
          padding: 4,
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            display: "flex",
            alignItems: "space-between",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
          }}
        >
          <Grid item>
            <Grid item>
              <Typography
                variant="h4"
                sx={{ color: (theme) => theme.palette.primary[800] }}
              >
                {startCase(establishment.name)}
              </Typography>
            </Grid>
            <Grid item>
              {isFreeAccount ? (
                <Typography
                  variant="overline"
                  sx={{
                    fontWeight: 700,
                    color: (theme) => theme.palette.terrain[600],
                    marginRight: 2,
                  }}
                >
                  Spec Free Account
                </Typography>
              ) : (
                <Typography
                  variant="overline"
                  sx={{
                    fontWeight: 700,
                    color: (theme) => theme.palette.terrain[600],
                  }}
                >
                  Spec Pro Account
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item>
                <LocationOnOutlinedIcon
                  sx={{ color: (theme) => theme.palette.primary[800] }}
                />{" "}
              </Grid>
              <Grid item sx={{ marginLeft: 1 }}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="body2">
                      {startCase(establishment.address?.addressLine1)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {" "}
                    <Typography variant="body2">
                      {establishment?.address?.city
                        ? `${startCase(establishment.address?.city)}, `
                        : ""}
                      {establishment.address?.stateProvince
                        ? `${startCase(establishment.address?.stateProvince)} `
                        : ""}
                      {establishment.address?.postalCode
                        ? `${startCase(establishment.address?.postalCode)} `
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EstablishmentCard;
