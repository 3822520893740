import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  MenuItem,
  Popover,
  Box,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import LogoutIcon from "@mui/icons-material/Logout";
import Store from "../../Store/Store.js";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import useUserProfile from "../../hooks/useUserProfile.js";
import SpecLogoMain from "../CustomIcons/SpecLogoMain.js";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useNavigate } from "react-router-dom";

const Header = ({ isViewingProfile }) => {
  const { data: userProfileData, isLoading: isLoadingProfile } =
    useUserProfile();
  const store = useContext(Store);
  const [anchor, setAnchor] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchor);

  const handlePopoverOpen = (event) => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleViewProfile = () => {
    if (isViewingProfile) {
      return;
    }

    navigate("/profile-details");
  }

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const menuIconButton = {
    display: "flex",
    marginLeft: { xs: 1, sm: 2 },
    width: "32px",
    height: "32px",
    borderRadius: 150,
    color: (theme) => theme.palette.terrain["800"],
    backgroundColor: "#000",
    border: "2px solid",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: (theme) => theme.palette.primary.main,
      borderRadius: 150,
    },
  };

  const welcomeHeading = () => {
    return (
      <Grid
        container
        sx={{
          padding: 5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          maxWidth: "2440px",
          margin: "auto",
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <SpecLogoMain />
          <Typography variant="h2" sx={{ marginLeft: 1 }}>
            Spec
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h2">
            {isLoadingProfile || !userProfileData?.profile?.firstName
              ? "Welcome to Spec"
              : "Welcome, " + userProfileData.profile?.firstName}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            color="inherit"
            title="Profile"
            sx={[
              menuIconButton,
              {
                borderColor: isViewingProfile
                  ? (theme) => theme.palette.primary[300]
                  : (theme) => theme.palette.terrain[800],
              },
            ]}
            onClick={handleViewProfile}
          >
            <PersonOutlineOutlinedIcon
              sx={{
                fontSize: { xs: 14, sm: 18 },
                color: isViewingProfile
                  ? (theme) => theme.palette.primary[300]
                  : "#fff",
              }}
            />
          </IconButton>
          <IconButton
            color="inherit"
            title="Profile"
            sx={menuIconButton}
            onClick={handlePopoverOpen}
          >
            <MoreHorizOutlinedIcon
              sx={{ color: "#fff" }}
              aria-describedby="basic-popover"
            />
          </IconButton>

          <Popover
            id="basic-popover"
            open={open}
            anchorEl={anchor}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                boxShadow: "0px 8px 40px 0px rgba(0, 0, 0, 0.25)",
                background: (theme) => theme.palette.terrain[50],
                p: 2,
              }}
            >
              <MenuItem
                onClick={store.logout}
                sx={{
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.terrain["02"],
                  },
                }}
              >
                {<LogoutIcon sx={{ marginRight: "10px" }} />}Logout
              </MenuItem>
            </Box>
          </Popover>
        </Grid>
      </Grid>
    );
  };

  const displayDesktop = () => {
    return <Toolbar sx={{ height: "88px" }}>{welcomeHeading()}</Toolbar>;
  };

  return (
    <header>
      <AppBar
        sx={{
          background: "#000",
          minHeight: "88px",
          boxShadow: "none",
        }}
        position="static"
      >
        {displayDesktop()}
      </AppBar>
    </header>
  );
};

export default Header;
