import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";

import RecentlyUpdatedTable from "./components/RecentlyUpdatedTable";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FullSummary from "./components/FullSummary";
import FreemiumDashboard from "./components/FreemiumDashboard";
import RecentlyUpdatedIndex from "./components/RecentlyUpdatedIndex";
import useReportingOverview from "../../hooks/useReportingOverview";
import useStaffMembers from "../../hooks/useStaffMembers";
import { useParams } from "react-router-dom";
import SpecSpinner from "../../components/SpecSpinner";
import useEstablishment from "../../hooks/useEstablishment";
import Store from "../../Store/Store";
import { startCase } from "lodash";
import useRecentUpdates from "../../hooks/useRecentUpdates";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import useMyMemberships from "../../hooks/useMyMemberships";
import useUserProfile from "../../hooks/useUserProfile";

const Dashboard = () => {
  const [recentlyUpdatedAll, toggleRecentlyUpdatedAll] = useState(false);
  const { establishmentid: establishmentId } = useParams();
  const store = useContext(Store);
  const { data: staffMemberData, isLoading: isLoadingStaffMembers } =
    useStaffMembers(establishmentId);
  const { data: reportingOverviewData, isLoading: isLoadingReportingOverview } =
    useReportingOverview(establishmentId);
  const { data: establishmentData, isLoading: isLoadingEstablishment } =
    useEstablishment(establishmentId, store);
  const { data: profileData, isLoading: isLoadingProfile } = useUserProfile();
  const { data: recentUpdatesData, isLoading: isLoadingRecentUpdates } =
    useRecentUpdates(establishmentId);
    const { data: subscriptionState, isLoading: isLoadingSubscriptionState } =
    useEstablishmentSubscription(establishmentId);
    const { data: memberships, isLoading: isLoadingMemberships } =
    useMyMemberships();

  if (
    isLoadingReportingOverview ||
    isLoadingStaffMembers ||
    isLoadingEstablishment ||
    isLoadingProfile ||
    isLoadingRecentUpdates || 
    isLoadingSubscriptionState || 
    isLoadingMemberships
  ) {
    return <SpecSpinner text="Loading dashboard..." open />;
  }

  return (
    <>
      {recentlyUpdatedAll === true ? (
        <RecentlyUpdatedIndex
          toggleRecentlyUpdatedAll={toggleRecentlyUpdatedAll}
          staffData={staffMemberData?.membershipProfiles || []}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "2440px",
            padding: 4,
            marginTop: 6,
          }}
        >
          <Box>
            <Typography variant="h2">
              {" "}
              Hi {startCase(profileData.profile?.firstName || "")}!
            </Typography>
          </Box>
          {/* New User View vs Paid User View */}
          {subscriptionState?.isActive ? (
            <FullSummary
            inventoryReportingOverview={reportingOverviewData}
            staffMemberCount={staffMemberData?.membershipProfiles?.length}
            establishment={establishmentData}
          />
          ) : (
            <FreemiumDashboard
              establishment={establishmentData}
              inventoryReportingOverview={reportingOverviewData}
              staffMemberCount={staffMemberData?.membershipProfiles?.length}
              memberships={memberships}
            />
          )}

          {/* Recent Updated Table */}
          {recentUpdatesData?.recentChanges?.length > 0 && (
            <Box sx={{ marginTop: 6 }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item>
                  <Typography variant="h4">Recently Updated</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => toggleRecentlyUpdatedAll(true)}
                  >
                    <Typography
                      variant="largeButton"
                      sx={{ color: (theme) => theme.palette.primary[800] }}
                    >
                      See All
                    </Typography>
                    <ArrowForwardIosOutlinedIcon
                      sx={{
                        fontSize: 20,
                        color: (theme) => theme.palette.primary[800],
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ marginTop: 2 }}>
                <RecentlyUpdatedTable
                  recentUpdates={recentUpdatesData.recentChanges || []}
                  staffData={staffMemberData?.membershipProfiles || []}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Dashboard;
