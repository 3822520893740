const inventoryObjects = {
    stockCountTypes: {
        inventoryAudit: 1,
        partial: 2,
    },
    StockCountStatuses: {
        Unknown: 0,
        NotStarted: 1,
        InProgress: 2,
        Complete: 3,
        Cancelled: 4
    },
    OrderStatuses: {
        unknown: 0,
		preview: 1,
		draft: 2,
		ordered: 3,
		received: 4,
		partiallyReceived: 5,
		backOrdered: 6,
		cancelled: 7
    },
    invoiceStatuses: {
        unknown: 0,
        unreconciled: 1,
        reconciled: 2,
        approved: 3,
    },
    kegTransferTypes: {
        credit: 0,
        expense: 1,
    },
    miscLineItemTypes: {
        credit: 0,
        expense: 1,
    },
    changeSetTypes: {
        0: "Created",
        1: "Updated",
        2: "Deleted",
        3: "Completed"
    },
    areaCountTypes: {
        default: 0,
        unorganized: 1,
    },
    parStatusTypes: {
      unknown: 0,
      good: 1,
      under: 2,
      over: 3
  },
  productSortProperties: {
    name: 0,
    createdDate: 1
  },
  rateLimitType: {
    recipes: 0,
    menus: 1
  }
}

const inventoryCountTypesMap = {
    1: 'Full Audit',
    2: 'Partial Audit'
}

const productSizeTypes = {
    Volumetric: 1,
    Mass: 2,
    Arbitrary: 3
}

const unitOfMeasurementTypes = {
    Volumetric: 0,
    Mass: 1,
    Arbitrary: 2
}

const unitSizeToUomSizeTypeMap = {
  1: 0,
  2: 1,
  3: 2,
}

const menuItemTypes = {
    Recipe: 0,
    ProductFormat: 1
}

const recipeIngredientTypes = {
    ProductFormat: 0,
    Recipe: 1,
    OpenText: 2
}

export default inventoryObjects;

export {inventoryCountTypesMap, productSizeTypes, unitOfMeasurementTypes, menuItemTypes, recipeIngredientTypes, unitSizeToUomSizeTypeMap };