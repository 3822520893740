import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const widgetStyleTop = {
  height: "180px",
  width: "100%",
  backgroundColor: (theme) => theme.palette.pure.white,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  borderRadius: 2,
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  padding: 4,
  cursor: "pointer",
};

// const widgetStyleBottom = {
//   height: "40px",
//   width: "100%",
//   backgroundColor: (theme) => theme.palette.primary[800],
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   borderBottomLeftRadius: 8,
//   borderBottomRightRadius: 8,
//   border: "1px solid",
//   borderTop: "none",
//   borderColor: (theme) => theme.palette.terrain[400],
// };

const SummaryCard = ({ header, amount, targetRoute, displayRestrictions }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(targetRoute);
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box onClick={handleNavigation} sx={widgetStyleTop}>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h3">{header}</Typography>
          {displayRestrictions === true ? (
            <Typography
              variant="body2"
              sx={{ color: (theme) => theme.palette.terrain[500] }}
            >
              Free Plan Restrictions
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography
            variant="h1"
            sx={{ color: (theme) => theme.palette.primary[800] }}
          >
            {amount}
          </Typography>
        </Box>
      </Box>
      {/* <Box
        sx={[
          widgetStyleBottom,
          {
            cursor: "pointer",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary[400],
            },
          },
        ]}
        
      >
        <Typography
          variant="largeButton"
          sx={{ color: (theme) => theme.palette.pure.white }}
        >
          View All
        </Typography>
        <ArrowForwardIosOutlinedIcon
          sx={{
            fontSize: 20,
            color: (theme) => theme.palette.pure.white,
            marginLeft: 1,
          }}
        />
      </Box> */}
    </Box>
  );
};

export default SummaryCard;
