import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate } from "react-router-dom";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const areaCardStyle = {
  zIndex: 0,
  borderRadius: 2,
  padding: 2,
  width: "100%",
  height: "80px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  border: "3px solid",
  borderColor: (theme) => theme.palette.terrain[300],
  backgroundColor: (theme) => theme.palette.pure.white,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: (theme) => theme.palette.terrain[300],
  },
};

const iconBorder = {
  margin: "auto",
  width: "44px",
  height: "44px",
  borderRadius: "100%",
  border: "2px solid",
  borderColor: (theme) => theme.palette.terrain[900],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const AreaCard = ({ area, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDeleteAreaModal = (id) => {
    onDelete(id);
    handleClose();
  };

  return (
    <>
      <Grid
        item
        xs={6}
        sm={4}
        md={4}
        lg={3}
        xl={2}
        key={area.id}
        sx={{ position: "relative" }}
      >
        <Box onClick={() => navigate(area.id)} sx={[areaCardStyle]}>
          <Box sx={{ width: "20%" }}>
            <Box sx={[iconBorder]}>
              <LocationOnOutlinedIcon
                sx={{
                  color: (theme) => theme.palette.terrain[800],
                }}
              />
            </Box>
          </Box>
          <Box
            component="div"
            sx={{
              maxHeight: "40px",
              width: "65%",
              maxWidth: "65%",
              marginLeft: 2,
            }}
          >
            <Typography variant="largeButton" noWrap sx={{ fontSize: 16 }}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  maxHeight: "40px",
                }}
              >
                {area.name}
              </div>
            </Typography>
          </Box>
          {/* ICON BUTTON GOES HERE */}
        </Box>
        <Grid
          item
          sx={{
            width: "32px",
            height: "32px",
            position: "absolute",
            bottom: "25%",
            right: 6,
          }}
        >
          <IconButton
            sx={{
              height: "32px",
              width: "32px",
              background: "none",
              "&:hover": {
                backgroundColor: "transparent",
                color: (theme) => theme.palette.primary["main"],
              },
            }}
          >
            <MoreHorizOutlinedIcon onClick={handleOptionClick} />
          </IconButton>
        </Grid>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            sx={{ margin: "5px" }}
            onClick={() => toggleDeleteAreaModal(area.id)}
          >
            <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
            Delete
          </MenuItem>
        </Menu>
      </Grid>
    </>
  );
};

export default AreaCard;
