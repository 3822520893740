import React, { useContext, useEffect, useState, memo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Box,
  Collapse,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
} from "@mui/material";
import ProductFormatSizingRow from "./ProductFormatSizingRow";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { cloneDeep } from "lodash";
import inventoryClient from "../../../../clients/inventoryClient";
import Store from "../../../../Store/Store";
import DescriptionToolTip from "../../../../components/DescriptionToolTip/";
import ProductSetupIllustrationModal from "../../../../components/Modals/ProductSetupIllustrationModal";

const tableHeadStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 2,
  background: (theme) => theme.palette.terrain[300],
  height: "24px",
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  borderTop: "none",
  lineHeight: 1.2,
  fontSize: 10,
  fontWeight: 600,
};

const tableCellStyle = {
  padding: 0,
  border: "1px solid",
  borderColor: (theme) => theme.palette.terrain[400],
  backgroundColor: (theme) => theme.palette.pure.white,
};

const ProductSizingCard = memo(function ProductSizingCard({
  product,
  unitsOfMeasurement,
  updateProductCallback,
  handleProductUnitSizeEdit,
  updateExpandedParent,
  refetchCurrentPageProducts,
}) {
  const [expanded, setExpanded] = useState(false);
  const [productCopy, setProductCopy] = useState({});
  const [productSetupModalOpen, toggleProductSetupModalOpen] = useState(false);
  const store = useContext(Store);

  useEffect(() => {
    setProductCopy(product);
    if (product.expanded === undefined) {
      setExpanded(true);
    } else {
      setExpanded(product.expanded);
    }
  }, [product]);

  const handleToggleProductSetupModal = () => {
    toggleProductSetupModalOpen(true);
  };

  const handleExpandClick = () => {
    updateExpandedParent(product.id, !expanded);
    setExpanded(!expanded);
  };

  const updateCallback = (format) => {
    const copyUpdate = cloneDeep(productCopy);

    copyUpdate.productFormats = copyUpdate.productFormats.map((x) => {
      if (x.id === format.id) {
        return format;
      }

      return x;
    });
    setProductCopy(copyUpdate);
    updateProductCallback(copyUpdate);
  };

  //CALLBACK FUNCTION TO ADD PRODUCT SIZING ROW
  const addSizingRow = async () => {
    const { data } = await inventoryClient.addProductFormat({
      establishmentId: store.currentEstablishment?.id,
      productId: product.id,
    });
    const pCopy = cloneDeep(product);
    pCopy.productFormats = pCopy.productFormats
      ? [...pCopy.productFormats, data.productFormat]
      : [data.productFormat];
    updateProductCallback(pCopy);
  };

  const deleteProductFormat = async (productFormatId) => {
    const response = await inventoryClient.deleteProductFormat({
      establishmentId: store.currentEstablishment?.id,
      productFormatId: productFormatId,
    });

    if (response.error) {
      console.log(response.message);
      return;
    }

    const pCopy = cloneDeep(product);
    pCopy.productFormats = pCopy.productFormats.filter(
      (x) => x.id !== productFormatId
    );
    updateProductCallback(pCopy);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          padding: 0,
          borderRadius: "8px",
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
        }}
      >
        <CardContent sx={{ width: "100%", height: "60px", padding: 0 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              height: "60px",
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <Grid item>
              <Grid
                container
                direction="row"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "60px",
                    width: "44px",
                    // borderRight: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                  }}
                >
                  {!expanded ? (
                    <IconButton
                      variant="outlined"
                      sx={{
                        margin: 2,
                        width: "32px",
                        height: "32px",
                      }}
                      onClick={handleExpandClick}
                    >
                      <KeyboardArrowDownOutlinedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="outlined"
                      sx={{
                        margin: 2,
                        width: "32px",
                        height: "32px",
                      }}
                      onClick={handleExpandClick}
                    >
                      <ExpandLessOutlinedIcon />
                    </IconButton>
                  )}
                </Box>
                <Grid item sx={{ marginLeft: 2 }}>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    <Typography variant="body2" onClick={handleExpandClick}>
                      {product ? product.name : "Product Name"}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: 0,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                      >
                        {product.productFamily &&
                          product.productFamily.id !== -1 &&
                          product.productFamily.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: (theme) => theme.palette.terrain[400],
                          marginLeft: 1,
                          marginRight: 1,
                        }}
                      >
                        {product.category && product.category.id !== -1 && "/"}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                      >
                        {product.category &&
                          product.category.id !== -1 &&
                          product.category.name}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          color: (theme) => theme.palette.terrain[400],
                          marginLeft: 1,
                          marginRight: 1,
                        }}
                      >
                        {product.subcategory &&
                          product.subcategory.id !== -1 &&
                          " / "}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: (theme) => theme.palette.primary[800] }}
                      >
                        {product.subcategory &&
                          product.subcategory.id !== -1 &&
                          product.subcategory.name}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box sx={{ marginRight: 2 }}>
                <Button
                  variant="text"
                  sx={{
                    color: (theme) => theme.palette.primary[800],
                  }}
                  onClick={addSizingRow}
                >
                  <AddOutlinedIcon
                    sx={{
                      fontSize: "14px",
                      color: (theme) => theme.palette.primary[800],
                    }}
                  />
                  <Typography
                    variant="smallButton"
                    sx={{ color: (theme) => theme.palette.primary[800] }}
                  >
                    Add Format
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Collapse in={expanded}>
          <CardContent
            sx={{
              backgroundColor: (theme) => theme.palette.terrain[50],
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[400],
              padding: 0,
            }}
          >
            <TableContainer
              sx={{
                margin: 0,
                width: "100%",
                minWidth: "700px",
                marginBottom: -6,
              }}
            >
              <Table sx={{ width: "100%" }} aria-label="customized table">
                <TableHead sx={{ height: "24px" }}>
                  <TableRow sx={{ height: 24 }}>
                    {/* CHECKBOX HEADER */}
                    {/* <TableCell
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[400],
                        background: (theme) => theme.palette.terrain[300],
                        padding: 0,
                        width: "32px !important",
                      }}
                    ></TableCell> */}
                    <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                      <Box
                        component="div"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Unit Size
                        <Box component="div" sx={{ marginRight: -3 }}>
                          <DescriptionToolTip
                            header="Unit Size"
                            buttonText="Show Illustration"
                            buttonAction={handleToggleProductSetupModal}
                            description={
                              <Box>
                                The quantifiable amount of product or good that
                                is packaged. <q>750 mL</q> is a common unit size
                                for spirits. You have the option to provide
                                conversions between unit of measurement types
                                `(volumetric, mass, or count)` so that a Product
                                Format can be portioned out in a recipe or menu
                                using either its volume or weight. This is
                                common for products like sugar where different
                                recipes will call for either a volume
                                measurement or mass measurement.
                                <br />
                                <br />
                                Keep in mind that a 750 mL bottle vodka should
                                be an entirely separate Product Format from a 1
                                L bottle of that same vodka.
                                <br />
                                <br />
                                This information is sent to your rep when
                                ordering to ensure you get sent the exact amount
                                of product you were needing.
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={[tableHeadStyle, { width: "160px" }]}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Packaging Type
                        <Box sx={{ marginRight: -2 }}>
                          <DescriptionToolTip
                            header="Packaging Type"
                            description="How is the product packaged? Bottle, can, keg, bag-in-box, and jar are all examples. This information is sent to your rep when ordering so you receive exactly what you want. Some establishments prefer Modelo in cans, and others in bottles!"
                            buttonText="Show Illustration"
                            buttonAction={handleToggleProductSetupModal}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={[tableHeadStyle, { width: "295px" }]}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        UPC
                        <Box sx={{ marginRight: -2 }}>
                          <DescriptionToolTip
                            header="UPC"
                            description={
                              <Box>
                                The universal product code, or barcode, found on
                                the label of the product.
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell sx={[tableHeadStyle, { width: "100px" }]}>
                      Image
                    </TableCell>
                    <TableCell sx={[tableHeadStyle, { width: "80px" }]}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Low Par
                        <Box sx={{ marginRight: -3 }}>
                          <DescriptionToolTip
                            header="Low Par"
                            description={
                              <Box>
                                The minimum number of Product Formats that
                                should be kept on hand at all times. This number
                                should be set with consideration to how quickly
                                you run through the product and how often it can
                                be ordered. For example, if you run through 1
                                keg of beer per week, and you can order it from
                                your vendor once per week, the low par should be
                                at least 1, if not slightly higher to account
                                for busier-than-average weeks.
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={[tableHeadStyle, { width: "80px" }]}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        High Par
                        <Box sx={{ marginRight: -3 }}>
                          <DescriptionToolTip
                            header="Low Par"
                            description={
                              <Box>
                                The maximum number of Product Formats that you
                                want to store on hand at a give time. For
                                example, if you never want more than two kegs of
                                the same beer at one time, then the high par
                                would be 2.
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={[tableHeadStyle, { width: "120px" }]}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        Status
                        <Box sx={{ marginRight: -2 }}>
                          <DescriptionToolTip
                            header="Status"
                            description={
                              <Box>
                                Active: the Product Format is actively being
                                reordered, used in your recipes, and is being
                                sold.
                                <br />
                                <br />
                                Inactive: the Product Format is no longer being
                                reordered or used anywhere in your
                                establishment.
                                <br />
                                <br />
                                Inactive Product Formats cannot be added to
                                recipes or menus, nor be seen by non-admin
                                users.
                              </Box>
                            }
                          />
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={[
                        tableHeadStyle,
                        { width: "40px", borderRight: "none" },
                      ]}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productCopy.productFormats?.map((x) => (
                    <ProductFormatSizingRow
                      key={x.id}
                      format={x}
                      tableCellStyle={tableCellStyle}
                      unitsOfMeasurement={unitsOfMeasurement}
                      updateProductCallback={updateCallback}
                      handleProductUnitSizeEdit={handleProductUnitSizeEdit}
                      product={product}
                      handleProductFormatDelete={deleteProductFormat}
                      refetchCurrentPageProducts={refetchCurrentPageProducts}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Collapse>
      </Card>
      <ProductSetupIllustrationModal
        isOpen={productSetupModalOpen}
        toggleModalOpen={toggleProductSetupModalOpen}
      />
    </>
  );
});

export default ProductSizingCard;
