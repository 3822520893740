import React, { useContext, useEffect, useRef, useState } from "react";
import MenuOption from "./components/MenuOption";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Store, { activeRouteKeys } from "../../../../Store/Store";
import {
  Switch,
  Typography,
  Menu,
  MenuItem,
  Pagination,
  TextField,
  CircularProgress,
  Alert,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CTABlock from "../../../../components/CTABlock";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../../../clients/inventoryClient";
import MenuManagementModalV2 from "../../../../components/Modals/MenuManagementModalV2";
import FilterListIcon from "@mui/icons-material/FilterList";
import useMenusPaginated from "../../../../hooks/useMenusPaginated";
import SpecSpinner from "../../../../components/SpecSpinner";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useMenuPaginatedSearch from "../../../../hooks/useMenuPaginatedSearch";
import { debounce } from "lodash";
import useEstablishmentRateLimits from "../../../../hooks/useEstablishmentRateLimits";
import useEstablishmentSubscription from "../../../../hooks/useEstablishmentSubscription";
import inventoryObjects from "../../../../clients/inventoryObjects";

const MenusDisplay = () => {
  const [creatingMenu, toggleCreatingMenu] = useState(false);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [searchContent, setSearchContent] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sortAsc: true,
    sortByCreatedDate: false,
    showInactive: false,
  });
  const navigate = useNavigate();
  const { establishmentid } = useParams();
  const { data: paginatedMenuData, isLoading: isLoadingPaginatedMenus } =
    useMenusPaginated(establishmentid, page, sort);
  const { data: paginatedSearchData, isLoading: isLoadingPaginatedSearchData } =
    useMenuPaginatedSearch(establishmentid, searchContent, page, sort);
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useEstablishmentSubscription(establishmentid);
  const { data: rateLimitData, isLoading: isLoadingRateLimits } =
    useEstablishmentRateLimits(
      establishmentid,
      subscriptionData?.isActive === false ? true : false
    );
  const queryClient = useQueryClient();
  const store = useContext(Store);
  const menuRef = useRef();

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.menu,
      `spec/${establishmentid}/menus`
    );

    if (localStorage.getItem("showInactiveMenus") !== null) {
      let sortObj = { ...sort };
      sortObj.showInactive = localStorage.getItem("showInactiveMenus");
      setSort(sortObj);
    }
  }, []);

  const handleToggleShowInactive = () => {
    let sortObj = { ...sort };
    sortObj.showInactive = !sort.showInactive;
    setSort(sortObj);
    localStorage.setItem("showInactiveMenus", sortObj.showInactive);
  };

  const addMenuMutation = useMutation(inventoryClient.addMenu, {
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: [
          "menus-paginated",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
        type: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [
          "rate-limits",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
        type: "all",
      });
      queryClient.refetchQueries({
        queryKey: [
          "rate-limits",
          {
            establishmentId: store.currentEstablishment?.id,
          },
        ],
        type: "all",
      });
      navigate(result.menu.id);
    },
  });

  const handlePageSet = (_, page) => {
    setPage(page);
    menuRef?.current?.scrollIntoView();
  };

  const handleSortAZ = () => {
    let sortObj = { ...sort };
    sortObj.sortAsc = true;
    sortObj.sortByCreatedDate = false;
    setSort(sortObj);
  };

  const handleSortZA = () => {
    let sortObj = { ...sort };
    sortObj.sortAsc = false;
    sortObj.sortByCreatedDate = false;
    setSort(sortObj);
  };

  const handleSortOldestNewest = () => {
    let sortObj = { ...sort };
    sortObj.sortAsc = false;
    sortObj.sortByCreatedDate = true;
    setSort(sortObj);
  };

  const handleSortNewestOldest = () => {
    let sortObj = { ...sort };
    sortObj.sortAsc = true;
    sortObj.sortByCreatedDate = true;
    setSort(sortObj);
  };

  const handleCloseSortMenu = () => {
    setSortMenuAnchor(null);
  };

  const handleOpenSortMenu = (e) => {
    setSortMenuAnchor(e.currentTarget);
  };

  const handleMenuCreateToggle = () => {
    toggleCreatingMenu(!creatingMenu);
  };

  const handleSearch = (e) => {
    if (!e.target.value) {
      queryClient.invalidateQueries({
        queryKey: ["menus-paginated"],
      });
      queryClient.refetchQueries({
        queryKey: ["menus-paginated"],
        type: "all",
      });
    }
    setSearchContent(e.target.value);
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleSearchChange = (e) => {
    debouncedSearchHandler.cancel();
    setPage(1);
    debouncedSearchHandler(e);
  };

  const handleMenuCreation = async (menuData) => {
    const menu = {
      name: menuData.name,
      establishmentId: establishmentid,
      isActive: menuData.active,
      isSharedWithStaff: menuData.isSharedWithStaff,
      defaultMarkUp: menuData.markUp,
      description: menuData.description,
    };
    await addMenuMutation.mutateAsync(menu);
  };

  if (isLoadingPaginatedMenus || isLoadingSubscription || isLoadingRateLimits) {
    return <SpecSpinner open text={"Loading Menus..."} />;
  }

  const menuRateLimits = rateLimitData?.sectionLimits?.find(
    (x) => x.type === inventoryObjects.rateLimitType.menus
  );
  const isRateLimited = subscriptionData?.isActive
    ? false
    : rateLimitData?.ignoreLimits || menuRateLimits?.isUnderLimit
    ? false
    : true;
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          maxWidth: "2440px",
          minHeight: "800px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {subscriptionData?.isActive === false ? (
          <Grid item sx={{ marginBottom: 2 }}>
            <Alert severity="warning">
              You are on a Spec Free plan. The Spec free plan includes up to 10
              free menus! Upgrade to Spec Pro for unlimited menus and enhanced
              costing and functionality!
            </Alert>{" "}
          </Grid>
        ) : null}
        {paginatedMenuData?.menus?.length > 0 ? (
          <Box>
            <Grid
              container
              sx={{
                marginBottom: 8,
                display: "flex",
              }}
            >
              <Grid item xs={6} sm={6} md={6} xl={6} lg={6}>
                <Typography variant="h2">Establishment Menus</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleMenuCreateToggle}
                  disabled={isRateLimited}
                  sx={{ borderRadius: 2, padding: 2.5 }}
                >
                  <AddOutlinedIcon sx={{ fontSize: 20, marginRight: 1 }} />
                  <Typography variant="smallButton">Create New Menu</Typography>
                </Button>
              </Grid>
            </Grid>
            <Box>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 6,
                  marginTop: 6,
                }}
              >
                <Grid>
                  {/* SEARCH BAR */}
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 4,
                      minWidth: "100%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoComplete="off"
                      id="recipeSearch"
                      onChange={handleSearchChange}
                      inputProps={{
                        sx: {
                          marginBottom: 1,
                        },
                      }}
                      sx={{
                        backgroundColor: (theme) => theme.palette.pure.white,
                        borderColor: (theme) => theme.palette.terrain[400],
                        width: "340px",
                        borderRadius: 1,
                      }}
                      label={
                        <>
                          <SearchOutlinedIcon style={{ paddingRight: "5px" }} />
                          Search for menus...
                        </>
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginBottom: -4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-betwen",
                      alignItems: "center",
                      width: "164px",
                      height: "32px",
                      borderRadius: 1,
                      backgroundColor: (theme) => theme.palette.pure.white,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[300],
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "space-betwen",
                        alignItems: "center",
                      }}
                    >
                      <Grid item>
                        <Switch
                          checked={sort.showInactive}
                          onChange={handleToggleShowInactive}
                          size="small"
                        />{" "}
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" sx={{ fontSize: 12 }}>
                          Show Inactive
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 1, marginTop: 8, marginLeft: 2 }}
                    onClick={handleOpenSortMenu}
                  >
                    <FilterListIcon sx={{ fontSize: 18, marginRight: 2 }} />
                    <Typography variant="largeButton">Sort</Typography>
                  </Button>
                  <Menu
                    anchorEl={sortMenuAnchor}
                    open={!!sortMenuAnchor}
                    onClose={handleCloseSortMenu}
                  >
                    <MenuItem
                      sx={{ margin: "5px" }}
                      selected={sort.sortAsc && !sort.sortByCreatedDate}
                      id="name,asc"
                      onClick={handleSortAZ}
                    >
                      Name A-Z
                    </MenuItem>
                    <MenuItem
                      sx={{ margin: "5px" }}
                      id="name,desc"
                      selected={!sort.sortAsc && !sort.sortByCreatedDate}
                      onClick={handleSortZA}
                    >
                      Name Z-A
                    </MenuItem>
                    <MenuItem
                      sx={{ margin: "5px" }}
                      id="createdDate,desc"
                      selected={sort.sortAsc && sort.sortByCreatedDate}
                      onClick={handleSortNewestOldest}
                    >
                      Newest First
                    </MenuItem>
                    <MenuItem
                      sx={{ margin: "5px" }}
                      id="createdDate,asc"
                      selected={!sort.sortAsc && sort.sortByCreatedDate}
                      onClick={handleSortOldestNewest}
                    >
                      Oldest First
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {isLoadingPaginatedSearchData ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : searchContent && paginatedSearchData?.menus.length > 0 ? (
                  paginatedSearchData?.menus.map((m) => (
                    <Grid item key={m.id} xs={6} sm={6} md={6} lg={4} xl={3}>
                      <MenuOption menu={m} handleClick={() => navigate(m.id)} />
                    </Grid>
                  ))
                ) : !searchContent && paginatedMenuData?.menus.length > 0 ? (
                  paginatedMenuData?.menus.map((m) => (
                    <Grid item key={m.id} xs={6} sm={6} md={6} lg={4} xl={3}>
                      <MenuOption menu={m} handleClick={() => navigate(m.id)} />
                    </Grid>
                  ))
                ) : searchContent && paginatedSearchData?.menus.length <= 0 ? (
                  <Grid sx={{ padding: "20px" }} item xs={12}>
                    <CTABlock
                      header="No Results Found"
                      hideButton
                      ButtonImage={AddOutlinedIcon}
                    />
                  </Grid>
                ) : (
                  <Grid sx={{ padding: "20px" }}>
                    <Box sx={{ paddingLeft: 5, marginBottom: 5 }}>
                      <Typography variant="h2">Menus</Typography>
                    </Box>
                    <CTABlock
                      header="Your Menu List is Empty"
                      subheader="You have not added any menus yet. Get started now..."
                      buttonText="CREATE NEW MENU"
                      ButtonImage={AddIcon}
                      handleCTA={() => toggleCreatingMenu(true)}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            <Pagination
              sx={{ marginTop: 6 }}
              count={
                searchContent
                  ? paginatedSearchData?.totalPages
                  : paginatedMenuData?.totalPages
              }
              onChange={handlePageSet}
              page={page}
            />
          </Box>
        ) : (
          <Grid sx={{ padding: "20px" }}>
            <Box sx={{ paddingLeft: 5, marginBottom: 5 }}>
              <Typography variant="h2">Menus</Typography>
            </Box>
            <CTABlock
              header="Your Menu List is Empty"
              subheader="You have not added any menus yet. Get started now..."
              buttonText="CREATE NEW MENU"
              ButtonImage={AddIcon}
              handleCTA={() => toggleCreatingMenu(true)}
            />
          </Grid>
        )}
      </Grid>
      <MenuManagementModalV2
        onSave={handleMenuCreation}
        modalOpen={creatingMenu}
        toggleModalOpen={handleMenuCreateToggle}
        isLoading={addMenuMutation.isLoading}
      />
    </>
  );
};

export default MenusDisplay;
