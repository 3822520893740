import React, { useEffect, useState } from "react";
import { modalBoxStyle } from "../styles";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as guidGenerator } from "uuid";

import {
  Typography,
  Box,
  Modal,
  Alert,
  TextField,
  Card,
  CardContent,
  Tabs,
  Tab,
  IconButton,
  Grid,
} from "@mui/material";
import TabPanel from "../../TabPanel";
import { cloneDeep } from "lodash";
import utilFunctions from "../../../utilities/utilFunctions";

import OrderDaySection from "../../../views/Vendors/components/AddDistributorCard/components/OrderDaySection";
import { useForm } from "react-hook-form";
import RepRowItem from "../../../views/Vendors/components/RepRowItem";
import OrderDayDetailSection from "./components/OrderDayDetailSection";
import inventoryClient from "../../../clients/inventoryClient";
import EditVendorModalFooter from "./components/EditVendorModalFooter";

const tabStyle = {
  fontSize: "12px",
  textTransform: "none",
  "&:hover": {
    color: (theme) => theme.palette.primary.light,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: (theme) => theme.palette.primary.main,
    fontWeight: "700",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#d1eaff",
  },
};

const EditDistributorModal = ({
  distributor,
  open,
  toggleEditDistributorModal,
  distributorUpdateCallback,
}) => {
  const [errorMessage, toggleErrorMessage] = useState(false);
  const [distributorCopy, setDistributorCopy] = useState({});
  const [currentTab, setCurrentTab] = useState(0);

  const { register, handleSubmit, reset } = useForm({
    mode: "all",
  });

  useEffect(() => {
    setCurrentTab(0);
    setDistributorCopy(distributor || {});
    distributor && reset(distributor);
  }, [distributor]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const updateOrderDay = (newOrderDay) => {
    const dCopy = cloneDeep(distributorCopy);

    dCopy.validOrderDays = dCopy.validOrderDays.map((d) => {
      if (d.day == newOrderDay.day) {
        return newOrderDay;
      }

      return d;
    });
    setDistributorCopy(dCopy);
  };

  const updateOrderDays = (orderDays) => {
    const dCopy = cloneDeep(distributorCopy);

    const validOrderDays = orderDays.map((o) => ({
      day: o,
      orderCutOffTime: "12:00am",
      noCutOffTime: false,
      expectedDeliveryDay: utilFunctions.getNextDayInt(o),
    }));
    dCopy.validOrderDays = validOrderDays;
    setDistributorCopy(dCopy);
  };

  const addRep = () => {
    const newContactObject = {
      name: "",
      email: "",
      phoneNumber: "",
      id: guidGenerator(),
    };
    const dCopy = cloneDeep(distributorCopy);
    dCopy.contacts = dCopy.contacts
      ? [...dCopy.contacts, newContactObject]
      : [newContactObject];
    setDistributorCopy(dCopy);
  };

  const updateRep = (rep) => {
    const dCopy = cloneDeep(distributorCopy);
    let index = dCopy.contacts.findIndex((x) => x.id === rep.id);
    dCopy.contacts[index] = rep;
    setDistributorCopy(dCopy);
  };

  const handleUpdateSubmit = async (formData) => {
    const response = await inventoryClient.updateDistributor({
      ...distributorCopy,
      ...{ name: formData.name },
    });
    distributorUpdateCallback(response);
  };

  const toggle = () => {
    toggleErrorMessage(false);
    setCurrentTab(0);
    setDistributorCopy({});
    toggleEditDistributorModal();
  };

  return (
    <Modal open={open} onClose={toggle}>
      {distributor ? (
        <Box
          sx={[
            modalBoxStyle,
            {
              padding: 0,
              paddingBottom: 8,
              height: "600px",
              overflow: "scroll",
            },
          ]}
        >
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "2rem",
                  marginBottom: "1rem",
                  padding: 4,
                  marginTop: 4,
                }}
              >
                Edit Details
              </Typography>
            </Grid>
            <Grid item sx={{ marginRight: 4 }}>
              <IconButton onClick={toggle}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{
              marginLeft: 4,
              marginRight: 4,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={8}>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                sx={{
                  marginBottom: 0,
                  "& .MuiTabs-indicator": {
                    backgroundColor: (theme) => theme.palette.primary.main,
                    height: 3,
                  },
                }}
              >
                <Tab component={"span"} sx={tabStyle} label="Vendor Info" />
                <Tab component={"span"} sx={tabStyle} label="Rep Info" />
                <Tab component={"span"} sx={tabStyle} label="Delivery Info" />
              </Tabs>
            </Grid>
            {currentTab === 1 && (
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  variant="outlined"
                  onClick={addRep}
                  sx={{
                    display: "flex",
                    color: (theme) => theme.palette.primary.main,
                    margin: 2,
                    border: "3px solid",
                    width: "40px",
                    height: "40px",
                    marginRight: 8,
                  }}
                >
                  <PersonAddAlt1OutlinedIcon
                    sx={{
                      color: (theme) => theme.palette.terrain["900"],
                    }}
                  />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Box sx={{ marginTop: 6 }}>
            <TabPanel value={currentTab} index={0}>
              <form onSubmit={handleSubmit(handleUpdateSubmit)}>
                <Card
                  elevation={0}
                  sx={{
                    border: "1px solid",
                    borderColor: (theme) => theme.palette.terrain[300],
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.palette.terrain[50],
                  }}
                >
                  <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: 4 }}>
                      Vendor Name
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      name="name"
                      id="name"
                      label="Vendor Name"
                      placeholder="Enter distributor name"
                      InputLabelProps={{ shrink: true, required: true }}
                      {...register("name", {
                        required: "Please enter distributor name",
                      })}
                    />
                  </CardContent>
                </Card>
              </form>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {distributorCopy.contacts && (
                <Box>
                  {distributorCopy.contacts &&
                  distributorCopy.contacts.length > 0 ? (
                    distributorCopy.contacts?.map((x) => (
                      <Card
                        key={x.id}
                        elevation={0}
                        sx={{
                          height: "68px",
                          border: "1px solid",
                          borderColor: (theme) => theme.palette.terrain[300],
                          borderRadius: 2,
                          backgroundColor: (theme) => theme.palette.terrain[50],
                          marginBottom: 4,
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <RepRowItem
                            updateRep={updateRep}
                            rep={x}
                            key={x.id}
                            modalView={true}
                          />
                        </CardContent>
                      </Card>
                    ))
                  ) : (
                    <Typography>No Contacts Added</Typography>
                  )}
                </Box>
              )}
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Box sx={{ width: "100%" }}>
                <OrderDaySection
                  distributor={distributorCopy}
                  updateOrderDays={updateOrderDays}
                  modalView
                />
              </Box>
              <Box>
                <OrderDayDetailSection
                  updateOrderDay={updateOrderDay}
                  validOrderDays={distributorCopy.validOrderDays}
                />
              </Box>
            </TabPanel>
          </Box>
          <Box>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "1rem" }}>
                There was an error trying to add your distributor. Please
                refresh and try again.
              </Alert>
            )}
          </Box>
          <Box>
            <EditVendorModalFooter
              reactFormSubmit={handleSubmit}
              submissionCallback={handleUpdateSubmit}
            />
          </Box>
        </Box>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

export default EditDistributorModal;
