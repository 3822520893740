import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { v4 as guidGenerator } from "uuid";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import RepRowItem from "../RepRowItem";

import { cloneDeep } from "lodash";
import CutOffTimeSection from "./components/CutOffTimeSection";
import Store from "../../../../Store/Store";
import OrderDaySection from "./components/OrderDaySection";
import utilFunctions from "../../../../utilities/utilFunctions";
import DeliveryDaySection from "./components/DeliveryDaySection";
import ReviewSection from "./components/ReviewSection";
import CutOffTimeBadge from "./components/CutOffTimeBadge";
var moment = require("moment-timezone");

const AddDistributorCard = ({
  step,
  distributor,
  updateDistributor,
  timeZones,
  toggleDeleteDistributorModalOpen,
}) => {
  const orderDayMap = {
    0: "Mon",
    1: "Tues",
    2: "Wed",
    3: "Thurs",
    4: "Fri",
    5: "Sat",
    6: "Sun",
  };
  const store = useContext(Store);
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const updateRep = (rep) => {
    const dCopy = cloneDeep(distributor);
    let index = dCopy.contacts.findIndex((x) => x.id === rep.id);
    dCopy.contacts[index] = rep;
    updateDistributor(dCopy);
  };

  const addRep = () => {
    const newContactObject = {
      name: "",
      email: "",
      phoneNumber: "",
      id: guidGenerator(),
    };
    const dCopy = cloneDeep(distributor);
    dCopy.contacts = dCopy.contacts
      ? [...dCopy.contacts, newContactObject]
      : [newContactObject];
    updateDistributor(dCopy);
  };

  const removeRep = (id) => {
    const dCopy = cloneDeep(distributor);
    dCopy.contacts = dCopy.contacts?.filter((c) => c.id != id);
    updateDistributor(dCopy);
  };

  const updateOrderDays = (orderDays) => {
    const dCopy = cloneDeep(distributor);

    const validOrderDays = orderDays.map((o) => ({
      day: o,
      orderCutOffTimeZone: store.user.country || moment.tz.guess(),
      orderCutOffTime: "12:00am",
      noCutOffTime: false,
      expectedDeliveryDay: utilFunctions.getNextDayInt(o),
    }));
    dCopy.validOrderDays = validOrderDays;
    updateDistributor(dCopy);
  };

  const updateOrderDay = (newOrderDay) => {
    const dCopy = cloneDeep(distributor);

    dCopy.validOrderDays = dCopy.validOrderDays.map((d) => {
      if (d.day == newOrderDay.day) {
        return newOrderDay;
      }

      return d;
    });
    updateDistributor(dCopy);
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
        padding: 0,
        borderRadius: "8px",
        marginTop: 4,
        border: "1px solid",
        borderColor: (theme) => theme.palette.terrain["300"],
      }}
    >
      <CardContent sx={{ width: "100%", height: "68px", padding: 0 }}>
        <Grid
          container
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            height: "68px",
            marginBottom: 0,
          }}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 4,
                }}
              >
                <Box sx={{ marginRight: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "48px",
                      height: "48px",
                      border: "3px solid",
                      borderColor: (theme) => theme.palette.terrain[600],
                      borderRadius: "150px",
                      overflow: "hidden",
                    }}
                  >
                    <LocalShippingOutlinedIcon
                      sx={{ color: (theme) => theme.palette.terrain[600] }}
                    />
                  </Box>
                </Box>
                <Grid item sx={{ marginBottom: 2 }}>
                  <Box>
                    <Typography variant="h4">
                      {distributor.name || "Distributor Name"}
                    </Typography>
                  </Box>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {distributor.validOrderDays
                      ?.sort((a, b) => a.day - b.day)
                      .map((orderDay) => (
                        <Box
                          key={orderDay.day}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.terrain[200],
                            marginRight: 2,
                            borderRadius: "2px",
                            width: "30px",
                            height: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="overline"
                            sx={{
                              color: (theme) => theme.palette.pure.black,
                            }}
                          >
                            {orderDayMap[orderDay.day]}
                          </Typography>
                        </Box>
                      ))
                      .sort((a, b) => a.day - b.day)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {step === 0 ? (
                  <Box>
                    <IconButton
                      variant="outlined"
                      onClick={addRep}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        margin: 2,
                        border: "3px solid",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <PersonAddAlt1OutlinedIcon
                        sx={{
                          color: (theme) => theme.palette.terrain["900"],
                        }}
                      />
                    </IconButton>
                    <IconButton
                      variant="outlined"
                      onClick={toggleDeleteDistributorModalOpen}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        margin: 2,
                        border: "3px solid",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <DeleteOutlineOutlinedIcon
                        sx={{
                          color: (theme) => theme.palette.terrain["900"],
                        }}
                      />
                    </IconButton>
                  </Box>
                ) : (
                  <Box>
                    <IconButton
                      variant="outlined"
                      onClick={toggleDeleteDistributorModalOpen}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        margin: 2,
                        border: "3px solid",
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <DeleteOutlineOutlinedIcon
                        sx={{
                          color: (theme) => theme.palette.terrain["900"],
                        }}
                      />
                    </IconButton>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      {step === 4 ? (
        <CardContent
          sx={{ height: "36px", width: "100%", margin: 0, padding: 0 }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "36px",
              backgroundColor: (theme) => theme.palette.terrain[800],
              color: (theme) => theme.palette.pure.white,
            }}
          >
            <Typography variant="h5" sx={{ marginLeft: 2 }}>
              Delivery Days and Cutoff Times
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "36px",
              }}
            >
              {!expanded ? (
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.pure.white,
                    height: "36px",
                    background: "none !important",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.pure.white,
                    height: "36px",
                    background: "none !important",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <ExpandLessOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </CardContent>
      ) : (
        <Grid item sx={{ height: "0px" }}></Grid>
      )}
      {step === 4 ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              height: "80px",
              backgroundColor: (theme) => theme.palette.terrain[900],
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[100],
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {step === 4 &&
                distributor.validOrderDays
                  ?.sort((a, b) => a.day - b.day)
                  .map((d) => <CutOffTimeBadge key={d.day} orderDay={d} />)}
            </Grid>
          </CardContent>
        </Collapse>
      ) : null}
      {step === 0 && (
        <CardContent
          sx={{ height: "36px", width: "100%", margin: 0, padding: 0 }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "36px",
              backgroundColor: (theme) => theme.palette.terrain[800],
              color: (theme) => theme.palette.pure.white,
            }}
          >
            <Typography variant="h5" sx={{ marginLeft: 4 }}>
              Distributor Reps
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "36px",
              }}
            >
              {!expanded ? (
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.pure.white,
                    height: "36px",
                    background: "none",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.pure.white,
                    height: "36px",
                    background: "none",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <ExpandLessOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </CardContent>
      )}

      {step === 0 && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: 6,
              marginLeft: 4,
            }}
          >
            {distributor.contacts && distributor.contacts.length > 0 ? (
              distributor.contacts?.map((x) => (
                <RepRowItem
                  updateRep={updateRep}
                  removeRep={removeRep}
                  rep={x}
                  key={x.id}
                />
              ))
            ) : (
              <Box sx={{ marginBottom: 6 }}>
                <Typography>No Contacts Added</Typography>
              </Box>
            )}
          </Box>
        </Collapse>
      )}
      {step != 0 && (
        <CardContent
          sx={{
            backgroundColor: (theme) => theme.palette.terrain[50],
            borderTop: "1px solid",
            borderColor: (theme) => theme.palette.terrain[300],
            minHeight: "66px",
          }}
        >
          {step === 1 && (
            <OrderDaySection
              distributor={distributor}
              updateOrderDays={updateOrderDays}
            />
          )}
          {step === 2 &&
            distributor.validOrderDays
              ?.sort((a, b) => a.day - b.day)
              .map((d) => (
                <CutOffTimeSection
                  key={d.day}
                  orderDay={d}
                  timeZones={timeZones}
                  updateOrderDay={updateOrderDay}
                />
              ))}
          {step === 3 &&
            distributor.validOrderDays
              ?.sort((a, b) => a.day - b.day)
              .map((d) => (
                <DeliveryDaySection
                  key={d.day}
                  orderDay={d}
                  updateOrderDay={updateOrderDay}
                />
              ))}
          {step === 4 && (
            <ReviewSection key={distributor.id} distributor={distributor} />
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default AddDistributorCard;
