import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { IconButton, Menu, MenuItem, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import Typography from "@mui/material/Typography";
import SpecBackButton from "../../components/SpecBackButton";
import CTABlock from "../../components/CTABlock";
import { useNavigate, useParams } from "react-router-dom";
import utilFunctions from "../../utilities/utilFunctions";
import SpecSpinner from "../../components/SpecSpinner";
import MenuListTable from "./components/MenuListTable";
import AddMenuItems from "./components/AddMenuItems";
import MenuDataCard from "./components/MenuDataCard";
import useMenu from "../../hooks/useMenu";
import useEstablishmentRecipes from "../../hooks/useEstablishmentRecipes";
import useProductLibrary from "../../hooks/useProductLibrary";
import useEstablishmentUoMs from "../../hooks/useEstablishmentUoMs";
import useVendorCurrency from "../../hooks/useVendorCurrency";
import useEstablishment from "../../hooks/useEstablishment";
import Store, { activeRouteKeys } from "../../Store/Store";
import MenuManagementModalV2 from "../../components/Modals/MenuManagementModalV2";
import { useMutation, useQueryClient } from "react-query";
import inventoryClient from "../../clients/inventoryClient";
import useEstablishmentSettings from "../../hooks/useEstablishmentSettings";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import { cloneDeep } from "lodash";

const MenuDetails = () => {
  const [updatingMenu, toggleUpdatingMenu] = useState(false);
  let { menuid, establishmentid } = useParams();
  const queryClient = useQueryClient();
  const [activeRecipes, setActiveRecipes] = useState([]);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [sortReverseAlphabeticaly, toggleSortReverseAlphabeticaly] =
    useState(false);
  const navigate = useNavigate();
  const {
    data: menuData,
    isLoading: isLoadingMenu,
    error: menuLoadingError,
  } = useMenu(establishmentid, menuid);
  const { data: recipeData, isLoading: isLoadingRecipes } =
    useEstablishmentRecipes(establishmentid);
  const [
    { data: productLibraryData, isLoading: isLoadingProducts },
    // eslint-disable-next-line no-unused-vars
    _,
  ] = useProductLibrary(establishmentid);
  const { data: establishmentUoMsData, isLoading: isLoadingUoMs } =
    useEstablishmentUoMs(establishmentid);
  const { data: subscriptionState, isLoading: isLoadingSubscriptionState } =
    useEstablishmentSubscription(establishmentid);
  const [addingItems, toggleAddingItems] = useState(false);
  const store = useContext(Store);
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  useEstablishment(establishmentid, store);
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.menu,
      `spec/${establishmentid}/menus/${menuid}`
    );
  }, []);

  useEffect(() => {
    const filteredRecipes = recipeData?.recipes.filter((x) => x.isActive);
    setActiveRecipes(filteredRecipes);
  }, [recipeData]);

  const updateMenuCache = (menuResult) => {
    const state = queryClient.getQueryState({
      queryKey: [
        "menu",
        {
          establishmentId: establishmentid,
          menuId: menu.id,
        },
      ],
    });

    const newState = state.data;
    newState.menu = cloneDeep(newState.menu);
    newState.menu.name = menuResult?.menu?.name;
    newState.menu.isActive = menuResult?.menu?.isActive;
    newState.menu.isSharedWithStaff = menuResult?.menu?.isSharedWithStaff;

    queryClient.setQueryData(
      [
        "menu",
        {
          establishmentId: establishmentid,
          menuId: menu.id,
        },
      ],
      newState
    );
  };

  const menuMutation = useMutation(inventoryClient.patchMenu, {
    onSuccess: (response) => {
      updateMenuCache(response);
      queryClient.invalidateQueries({
        queryKey: [
          "menu",
          {
            establishmentId: establishmentid,
            menuId: menu.id,
          },
        ],
      });
      queryClient.refetchQueries({
        queryKey: [
          "menu",
          {
            establishmentId: establishmentid,
            menuId: menu.id,
          },
        ],
      });

      queryClient.invalidateQueries({
        queryKey: ["menus"],
      });
      queryClient.refetchQueries({
        queryKey: ["menus"],
      });
    },
  });

  const handleCloseSortMenu = () => {
    setSortMenuAnchor(null);
  };

  const handleOpenSortMenu = (e) => {
    setSortMenuAnchor(e.currentTarget);
  };

  const handleEditMenuToggle = () => {
    toggleUpdatingMenu(!updatingMenu);
  };

  const handleSortAlphabeticaly = () => {
    toggleSortReverseAlphabeticaly(false);
    setSortMenuAnchor(null);
  };

  const handleSortReverseAlphabeticaly = () => {
    toggleSortReverseAlphabeticaly(true);
    setSortMenuAnchor(null);
  };

  if (
    isLoadingMenu ||
    isLoadingRecipes ||
    isLoadingUoMs ||
    isLoadingSubscriptionState
  ) {
    return <SpecSpinner open text="Loading Menu..." />;
  }

  if (menuLoadingError) {
    return (
      <Typography variant="h2" sx={{ color: "red" }}>
        There was an error fetching your menu. Please try refreshing.
      </Typography>
    );
  }

  const menu = menuData.menu;

  if (addingItems) {
    return (
      <AddMenuItems
        menu={menu}
        handleBack={() => toggleAddingItems(false)}
        productLibrary={productLibraryData?.products}
        isLoadingLibrary={isLoadingProducts}
        recipes={activeRecipes}
      />
    );
  }

  const calculateAverageIngCost = () => {
    let ingsInCosting = 0;
    let ingCostTotal = 0;

    menu?.menuItems.forEach((x) => {
      if (x.costAnalysis?.isValid) {
        ingsInCosting += 1;
        ingCostTotal += x.costAnalysis.ingredientCost?.result?.amount || 0;
      }
    });

    if (ingsInCosting === 0 || ingCostTotal === 0) {
      return 0;
    }

    return utilFunctions.convertCentsToLargerFormatCurrency(
      ingCostTotal / ingsInCosting,
      locale,
      currency
    );
  };

  const calculateAverageLaborCost = () => {
    let ingsInCosting = 0;
    let ingCostTotal = 0;

    menu?.menuItems.forEach((x) => {
      if (x.costAnalysis?.isValid && x.costAnalysis?.laborCost?.isValid) {
        ingsInCosting += 1;
        ingCostTotal += x.costAnalysis.laborCost?.result?.amount || 0;
      }
    });

    if (ingsInCosting === 0 || ingCostTotal === 0) {
      return 0;
    }

    return utilFunctions.convertCentsToLargerFormatCurrency(
      ingCostTotal / ingsInCosting,
      locale,
      currency
    );
  };

  const calculateAverageTotalCost = () => {
    let ingsInCosting = 0;
    let ingCostTotal = 0;

    menu?.menuItems.forEach((x) => {
      if (x.costAnalysis?.isValid && x.costAnalysis?.totalCost?.isValid) {
        ingsInCosting += 1;
        ingCostTotal += x.costAnalysis.totalCost?.result?.amount || 0;
      }
    });

    if (ingsInCosting === 0 || ingCostTotal === 0) {
      return 0;
    }

    return utilFunctions.convertCentsToLargerFormatCurrency(
      ingCostTotal / ingsInCosting,
      locale,
      currency
    );
  };

  const calculateAverageMenuPrice = () => {
    let ingsInCosting = 0;
    let ingCostTotal = 0;

    menu?.menuItems.forEach((x) => {
      ingsInCosting += 1;
      ingCostTotal += x.menuPrice.amount;
    });

    if (ingsInCosting === 0 || ingCostTotal === 0) {
      return 0;
    }

    return utilFunctions.convertCentsToLargerFormatCurrency(
      ingCostTotal / ingsInCosting,
      locale,
      currency
    );
  };

  const calculateAverageTargetSpread = () => {
    let ingsInCosting = 0;
    let ingCostTotal = 0;

    menu?.menuItems.forEach((x) => {
      if (x.costAnalysis?.isValid && x.costAnalysis?.targetSpread?.isValid) {
        ingsInCosting += 1;
        ingCostTotal += x.costAnalysis.targetSpread?.result?.amount || 0;
      }
    });

    if (ingsInCosting === 0 || ingCostTotal === 0) {
      return 0;
    }

    return utilFunctions.convertCentsToLargerFormatCurrency(
      ingCostTotal / ingsInCosting,
      locale,
      currency
    );
  };

  const calculateAverageNetProfit = () => {
    let ingsInCosting = 0;
    let ingCostTotal = 0;

    menu?.menuItems.forEach((x) => {
      if (x.costAnalysis?.isValid && x.costAnalysis?.netProfit?.isValid) {
        ingsInCosting += 1;
        ingCostTotal += x.costAnalysis.netProfit?.result?.amount || 0;
      }
    });

    if (ingsInCosting === 0 || ingCostTotal === 0) {
      return 0;
    }

    return utilFunctions.convertCentsToLargerFormatCurrency(
      ingCostTotal / ingsInCosting,
      locale,
      currency
    );
  };

  const handleEditMenu = (menuObj) => {
    let payload = {
      establishmentId: store.currentEstablishment?.id,
      menuId: menu.id,
    };

    payload["NameUpdate"] = { value: menuObj.name };
    payload["IsActiveUpdate"] = { value: menuObj.active };
    payload["DefaultMarkUpUpdate"] = { value: menuObj.markUp };
    payload["DescriptionUpdate"] = { value: menuObj.description };
    payload["isSharedWithStaffUpdate"] = { value: menuObj.isSharedWithStaff };
    menuMutation.mutate(payload);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: "2440px", padding: 4 }}
      >
        <Grid item>
          <SpecBackButton
            onClick={() => navigate(`/spec/${establishmentid}/menus`)}
          />
        </Grid>
        <Grid item sx={{ marginTop: 6 }}>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item>
              <Grid item>
                <Grid container direction="column">
                  <Grid item sx={{ marginBottom: 4 }}>
                    <Grid
                      container
                      direction="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid item>
                        <Typography
                          variant="h2"
                          sx={{ marginRight: 2, fontSize: { xs: 18, sm: 24 } }}
                        >
                          {utilFunctions.capitalizeString(menu?.name)}
                          {menuMutation.isLoading ? (
                            <CircularProgress
                              size={16}
                              sx={{ marginLeft: 2 }}
                            />
                          ) : null}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ marginLeft: 2 }}>
                        <IconButton
                          sx={{
                            border: "1px solid",
                            color: (theme) => theme.palette.primary.main,
                            height: "28px",
                            width: "28px",
                            borderRadius: 2,
                          }}
                          variant="outlined"
                          onClick={handleEditMenuToggle}
                        >
                          <EditIcon
                            sx={{
                              width: "12px",
                              color: (theme) => theme.palette.pure.black,
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "140px",
                        height: "32px",
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[300],
                        backgroundColor: (theme) => theme.palette.pure.white,
                      }}
                    >
                      <Typography variant="body1">Menu Status: </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.info.main,
                          marginLeft: 1,
                        }}
                      >
                        {menu.isActive ? "Active" : "Inactive"}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "140px",
                        height: "32px",
                        marginLeft: 2,
                        borderRadius: 1,
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.terrain[300],
                        backgroundColor: (theme) => theme.palette.pure.white,
                      }}
                    >
                      <Typography variant="body1">Show to Staff: </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.info.main,
                          marginLeft: 1,
                        }}
                      >
                        {menu.isSharedWithStaff ? "Visbile" : "Hidden"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              sm={5}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {menu.menuItems?.length > 0 && !addingItems && (
                <Grid container justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ borderRadius: 2, padding: 4, height: "40px" }}
                    onClick={() => toggleAddingItems(true)}
                  >
                    <AddIcon sx={{ fontSize: { xs: 14, sm: 14, md: 20 } }} />
                    <Typography
                      variant="smallButton"
                      sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}
                    >
                      Add Items
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 6, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item>
                <MenuDataCard
                  headerText="Avg. Ingredient Cost"
                  prefixAdornment={currencySymbol}
                  subscriptionActive={subscriptionState?.isActive}
                  dataText={
                    subscriptionState?.isActive ? calculateAverageIngCost() : ""
                  }
                />
              </Grid>
              <Grid item>
                <MenuDataCard
                  headerText="Avg. Labor Cost"
                  prefixAdornment={currencySymbol}
                  subscriptionActive={subscriptionState?.isActive}
                  dataText={
                    subscriptionState?.isActive
                      ? calculateAverageLaborCost()
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <MenuDataCard
                  headerText="Avg. Total Cost"
                  prefixAdornment={currencySymbol}
                  subscriptionActive={subscriptionState?.isActive}
                  dataText={
                    subscriptionState?.isActive
                      ? calculateAverageTotalCost()
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <MenuDataCard
                  headerText="Avg. Menu Price"
                  prefixAdornment={currencySymbol}
                  subscriptionActive={subscriptionState?.isActive}
                  dataText={
                    subscriptionState?.isActive
                      ? calculateAverageMenuPrice()
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <MenuDataCard
                  headerText="Avg. Target Spread"
                  prefixAdornment={currencySymbol}
                  subscriptionActive={subscriptionState?.isActive}
                  dataText={
                    subscriptionState?.isActive
                      ? calculateAverageTargetSpread()
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <MenuDataCard
                  headerText="Avg. Net Profit"
                  prefixAdornment={currencySymbol}
                  subscriptionActive={subscriptionState?.isActive}
                  dataText={
                    subscriptionState?.isActive
                      ? calculateAverageNetProfit()
                      : ""
                  }
                />
              </Grid>
              <Grid item sx={{ marginLeft: "auto" }}>
                <Button
                  variant="outlined"
                  sx={{ marginRight: 1, marginLeft: "auto" }}
                  onClick={handleOpenSortMenu}
                >
                  <FilterListIcon sx={{ fontSize: 18, marginRight: 2 }} />
                  <Typography variant="largeButton">Sort</Typography>
                </Button>
                <Menu
                  anchorEl={sortMenuAnchor}
                  open={!!sortMenuAnchor}
                  onClose={handleCloseSortMenu}
                >
                  <MenuItem
                    sx={{ margin: "5px" }}
                    selected={!sortReverseAlphabeticaly}
                    id="name,asc"
                    onClick={handleSortAlphabeticaly}
                  >
                    Name A-Z
                  </MenuItem>
                  <MenuItem
                    sx={{ margin: "5px" }}
                    id="name,desc"
                    selected={sortReverseAlphabeticaly}
                    onClick={handleSortReverseAlphabeticaly}
                  >
                    Name Z-A
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Box>
          {menu?.menuItems?.length > 0 ? (
            <Box sx={{ marginTop: 6 }}>
              <MenuListTable
                menuItems={menu.menuItems}
                establishmentUoMs={establishmentUoMsData.unitsOfMeasurement}
                subscriptionState={subscriptionState}
                reverseAzOrder={sortReverseAlphabeticaly}
              />
            </Box>
          ) : (
            <Box sx={{ marginTop: 4 }}>
              <CTABlock
                header="Your Menu is Empty"
                subheader="You have not added anything to your Menu yet. Get started now..."
                buttonText="Add New Menu Item"
                ButtonImage={AddIcon}
                handleCTA={() => toggleAddingItems(true)}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <MenuManagementModalV2
        onSave={handleEditMenu}
        modalOpen={updatingMenu}
        toggleModalOpen={toggleUpdatingMenu}
        existingMenu={menu}
      />
      {/* <Box>
        <Snackbar
          open={alertSuccess}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {successMessageContent}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage}
          onClose={handleAlertClose}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessageContent}
          </Alert>
        </Snackbar>
      </Box> */}
    </>
  );
};

export default MenuDetails;
