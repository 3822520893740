import React, { useContext, useEffect, useState } from "react";
import Store from "../../Store/Store";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import configuration from "../../configuration.json";
import GoogleLogin from "react-google-login";
import Typography from "@mui/material/Typography";

import {
  exchangeUserToken,
  identityConfig,
} from "../../clients/identityClient";
import { getUserProfile } from "../../clients/authClient";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import Footer from "../../components/Footer";
import LogoStampWhite from "../../assets/img/LogoStampWhite.png";

// import backgroundDrinkImage from "../../assets/img/DrinkSpread.jpg";
import { Spinner } from "reactstrap";
import utilFunctions from "../../utilities/utilFunctions";
import { Box, Link, Button } from "@mui/material";
import AppleSignInButton from "react-apple-signin-auth";

const Register = ({ handleLogInSuccess }) => {
  const store = useContext(Store);
  const [loading, toggleLoading] = useState(true);
  const [fetchingComplete, toggleFetchingComplete] = useState(false);
  const [isLoadingTokenExchange, toggleIsLoadingTokenExchange] =
    useState(false);
  const [error, toggleError] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (location.pathname != "/") {
      navigate("/");
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
    const fetchUserData = async () => {
      const user = await getUserProfile();
      const tokenData = utilFunctions.parseJwt(identityConfig.accessToken);
      handleLogInSuccess(user.profile, tokenData.sub, true);
      toggleFetchingComplete(true);
      toggleLoading(false);
    };
    try {
      toggleError(false);
      if (store.authenticated) {
        fetchUserData();
      } else {
        toggleLoading(false);
        toggleFetchingComplete(true);
      }
    } catch (err) {
      console.log(err);
      toggleError(true);
    }
  }, []);

  const handleSocialLoginSuccess = async (resp, socialProvider) => {
    try {
      toggleError(false);
      const token =
        socialProvider === "google" ? resp.tokenObj.id_token : socialProvider === "apple" ? resp?.authorization?.id_token : resp.accessToken;
      toggleIsLoadingTokenExchange(true);
      const excahngeResult = await exchangeUserToken(token, socialProvider);
      toggleIsLoadingTokenExchange(false);
      localStorage.setItem("at", excahngeResult.access_token);
      const tokenData = utilFunctions.parseJwt(excahngeResult.access_token);
      const user = await getUserProfile();
      handleLogInSuccess(user.profile, tokenData.sub);
    } catch (err) {
      toggleIsLoadingTokenExchange(false);
      console.log(err);
      toggleError(true);
    }
  };

  const handleGoogleLoginError = (resp) => {
    console.log(resp);
  };

  if (store.authenticated && fetchingComplete) {
    if (store.user && store.user.firstName) {
      navigate("/establishments");
    } else {
      navigate("/create-profile");
    }
  }
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          background: (theme) => theme.palette.gradient.primary,
          minHeight: "100%",
          padding: 6,
        }}
      >
        <Grid
          container
          sx={{
            maxWidth: "2440px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            // md={4}
            // lg={4}
            // xl={4}
            sx={{
              zIndex: 1,
              marginTop: "auto",
              marginBottom: "auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sx={{
                marginBottom: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  variant="h1"
                  sx={{
                    color: (theme) => theme.palette.terrain["04"],
                    fontSize: "60px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Welcome to
                  <img
                    alt="Spec Logo"
                    src={LogoStampWhite}
                    style={{
                      marginLeft: "20px",
                      marginRight: "8px",
                      height: "50px",
                    }}
                  />
                  Spec
                </Typography>
              </Box>
              <Typography
                variant="h3"
                sx={{ color: (theme) => theme.palette.terrain["04"] }}
              >
                Helping establishments maximize profits
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              sx={{
                display: "flex",
                marginTop: "auto",
                marginBottom: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: (theme) => theme.palette.terrain["04"],
                  textAlign: "left",
                  marginBottom: 6,
                }}
              >
                Already have a Spec Account?{" "}
                <Link
                  href="/login"
                  color="inherit"
                  sx={{ color: (theme) => theme.palette.secondary.main }}
                >
                  Log In
                </Link>
              </Typography>

              <Grid item>
                {loading ? (
                  <Spinner color="primary" size="lg" />
                ) : (
                  <Card
                    sx={{
                      maxWidth: "450px",
                      maxHeight: "500px",
                      marginBottom: 24,
                      borderRadius: 4,
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      border: (theme) =>
                        `4px solid ${theme.palette.terrain["04"]}`,
                      color: (theme) => theme.palette.terrain["04"],
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{ textAlign: "center", marginTop: 4 }}
                    >
                      Create Account
                    </Typography>
                    {error && (
                      <Typography
                        style={{
                          margin: "8px 4px 8px 4px",
                          color: "red",
                          textAlign: "center",
                        }}
                        variant="body1"
                      >
                        An error ocurred trying to log you in, please try again.
                      </Typography>
                    )}
                    <CardContent>
                      <Box
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <FacebookLogin
                          appId="465358737667031"
                          fields="name,email"
                          callback={(resp) =>
                            handleSocialLoginSuccess(resp, "facebook")
                          }
                          render={(renderProps) => (
                            <Button
                              variant="outlined"
                              onClick={renderProps.onClick}
                              disabled={isLoadingTokenExchange}
                              sx={{
                                width: "300px",
                                borderRadius: "8px",
                                backgroundColor: "#ffffff",
                                paddingTop: 3,
                                paddingBottom: 3,
                              }}
                            >
                              <svg
                                fill="#4a90e2"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                width="28px"
                                height="28px"
                              >
                                <path d="M 7.5 1 C 3.9160714 1 1 3.9160714 1 7.5 C 1 11.083929 3.9160714 14 7.5 14 C 11.083929 14 14 11.083929 14 7.5 C 14 3.9160714 11.083929 1 7.5 1 z M 7.5 2 C 10.543488 2 13 4.4565116 13 7.5 C 13 10.266333 10.967571 12.541024 8.3125 12.933594 L 8.3125 9.0898438 L 9.8652344 9.0898438 L 10.109375 7.5136719 L 8.3125 7.5136719 L 8.3125 6.6503906 C 8.3125 5.9953906 8.5256719 5.4140625 9.1386719 5.4140625 L 10.123047 5.4140625 L 10.123047 4.0371094 C 9.9500469 4.0141094 9.5845781 3.9628906 8.8925781 3.9628906 C 7.4485781 3.9628906 6.6015625 4.7258906 6.6015625 6.4628906 L 6.6015625 7.5117188 L 5.1171875 7.5117188 L 5.1171875 9.0898438 L 6.6035156 9.0898438 L 6.6035156 12.919922 C 3.9897868 12.492118 2 10.237066 2 7.5 C 2 4.4565116 4.4565116 2 7.5 2 z" />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{ marginLeft: 2, textTransform: "none" }}
                              >
                                Continue with Facebook
                              </Typography>
                            </Button>
                          )}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "1em",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <GoogleLogin
                          clientId="673490331700-tuqqeruom9fjv70ga71v70kj1k43rnvk.apps.googleusercontent.com"
                          buttonText="GOOGLE"
                          onSuccess={(resp) =>
                            handleSocialLoginSuccess(resp, "google")
                          }
                          render={(renderProps) => (
                            <Button
                              variant="outlined"
                              onClick={renderProps.onClick}
                              disabled={isLoadingTokenExchange}
                              sx={{
                                width: "300px",
                                borderRadius: "8px",
                                backgroundColor: "#ffffff",
                                paddingTop: 3,
                                paddingBottom: 3,
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 48 48"
                                width="24px"
                                height="24px"
                              >
                                <path
                                  fill="#FFC107"
                                  d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                                />
                                <path
                                  fill="#FF3D00"
                                  d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                                />
                                <path
                                  fill="#4CAF50"
                                  d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                                />
                                <path
                                  fill="#1976D2"
                                  d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                                />
                              </svg>
                              <Typography
                                variant="h6"
                                sx={{ marginLeft: 3, textTransform: "none" }}
                              >
                                Continue with Google
                              </Typography>
                            </Button>
                          )}
                          onFailure={handleGoogleLoginError}
                          cookiePolicy={"single_host_origin"}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: "1em",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <AppleSignInButton
                          authOptions={{
                            clientId: configuration.appleClientId,
                            scope: "email name",
                            redirectURI: configuration.appleRedirectUri,
                            state: "state",
                            nonce: "nonce",
                            usePopup: true,
                          }}
                          uiType="light"
                          className="apple-auth-btn"
                          buttonExtraChildren="Continue with Apple"
                          onSuccess={(response) => handleSocialLoginSuccess(response, "apple")}
                          onError={(error) => console.error(error)}
                          skipScript={false}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ textAlign: "center", marginTop: 6 }}
                        >
                          By creating your account, you agree to our{" "}
                          <Link
                            sx={{
                              color: (theme) => theme.palette.terrain["04"],
                            }}
                            color="inherit"
                            href="https://www.specapp.com/terms-policies"
                            target={"_blank"}
                          >
                            Terms of Service
                          </Link>{" "}
                          and{" "}
                          <Link
                            sx={{
                              color: (theme) => theme.palette.terrain["04"],
                            }}
                            color="inherit"
                            href="https://www.specapp.com/terms-policies"
                            target={"_blank"}
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    color: (theme) => theme.palette.terrain["04"],
                    textAlign: "center",
                  }}
                >
                  Account admins gain access to our dynamic dashboard feature
                  and are able to build out a digital home for your
                  establishment{"'"}s food and beverage content.
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: 6 }}>
                <Typography
                  variant="body1"
                  sx={{ color: (theme) => theme.palette.terrain["04"] }}
                >
                  Staff are able to download and view your content via our
                  mobile app:
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: 6 }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    marginTop: 4,
                    minWidth: "300px",
                  }}
                >
                  <Box>
                    <Link
                      href="https://apps.apple.com/us/app/spec-pro/id1454755076?itsct=apps_box_badge&amp;itscg=30200"
                      sx={{
                        width: "125px",
                      }}
                    >
                      <img
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1552176000&h=64eba89b8337a122e132b26421e24f4f"
                        alt="Download on the App Store"
                        style={{
                          width: "125px",
                        }}
                      />
                    </Link>
                  </Box>
                  <Box>
                    <Link href="https://play.google.com/store/apps/details?id=com.spec.pro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      <img
                        alt="Get it on Google Play"
                        src="https://mcusercontent.com/54c65f604256d6be9651d3858/images/199492b4-3f5c-ba0c-e1a2-6e304c90b1d1.png"
                        style={{ width: "160px" }}
                      />
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Register;
