import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { productSizeTypes } from "../../../clients/inventoryObjects";
import utilFunctions from "../../../utilities/utilFunctions";

const YieldToggle = ({ toggleYieldModalOpen, recipe }) => {
  if (
    recipe.yield?.find((x) => x.isDefaultSize)?.unitOfMeasurement === null &&
    recipe.yield?.find((x) => x.isDefaultSize)?.type !==
      productSizeTypes.Arbitrary
  ) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "124px",
          height: "36px",
          borderRadius: 1,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[400],
          backgroundColor: (theme) => theme.palette.pure.white,
        }}
      >
        <Button variant="text" onClick={() => toggleYieldModalOpen(true)}>
          <Typography variant="smallButton">+ Set Yield</Typography>
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => toggleYieldModalOpen(true)}
    >
      <Box>Yield:</Box>
      <Box
        sx={{
          height: "20px",
          backgroundColor: (theme) => theme.palette.info[600],
          borderRadius: 1,
          padding: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "fit-content",
          marginLeft: 2,
          cursor: "pointer",
        }}
        onClick={() => toggleYieldModalOpen(true)}
      >
        <Typography
          variant="overline"
          sx={{
            color: (theme) => theme.palette.pure.white,
            fontWeight: 700,
          }}
        >
          {utilFunctions.extractRecipeYieldSizeDisplay(recipe)}
        </Typography>
      </Box>
    </Box>
  );
};

export default YieldToggle;
