import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import CostProductListTable from "./CostProductListTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const CostBreakdown = () => {
  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          maxWidth: "2440px",
        }}
      >
        <Box sx={{ marginTop: 6 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography variant="h3">Cost Breakdown</Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined">
                <EditOutlinedIcon sx={{ fontSize: 18, marginRight: 1 }} />
                <Typography variant="largeButton">Edit Table</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: 6 }}>
          <CostProductListTable />
        </Box>
      </Grid>
    </>
  );
};

export default CostBreakdown;
